export const COUNTRIES = [
  {
    name: 'Afghanistan',
    translatedName: {
      en: 'Afghanistan',
      de: 'Afghanistan',
      nl: 'Afghanistan',
      es: 'Afganistán',
      fr: 'Afghanistan',
      hu: 'Afganisztán',
      ca: 'Afganistan',
    },
    topLevelDomain: ['.af'],
    alpha2Code: 'AF',
    alpha3Code: 'AFG',
    callingCodes: ['93'],
    capital: 'Kabul',
    altSpellings: ['AF', 'Afġānistān'],
    region: 'Asia'
  },
  {
    name: 'Åland Islands',
    translatedName: {
      en: 'Åland Island',
      de: 'Ålandinseln',
      nl: 'Aland-eilanden',
      es: 'Islas Aland',
      fr: 'Iles Aland',
      hu: 'Åland-szigetek',
      ca: 'Illes Åland',
    },
    topLevelDomain: ['.ax'],
    alpha2Code: 'AX',
    alpha3Code: 'ALA',
    callingCodes: ['358'],
    capital: 'Mariehamn',
    altSpellings: ['AX', 'Aaland', 'Aland', 'Ahvenanmaa'],
    region: 'Europe'
  },
  {
    name: 'Albania',
    translatedName: {
      en: 'Albania',
      de: 'Albanien',
      nl: 'Albanië',
      es: 'Albania',
      fr: 'Albanie',
      hu: 'Albánia',
      ca: 'Albània',
    },
    topLevelDomain: ['.al'],
    alpha2Code: 'AL',
    alpha3Code: 'ALB',
    callingCodes: ['355'],
    capital: 'Tirana',
    altSpellings: ['AL', 'Shqipëri', 'Shqipëria', 'Shqipnia'],
    region: 'Europe'
  },
  {
    name: 'Algeria',
    translatedName: {
      en: 'Algeria',
      de: 'Algerien',
      nl: 'Algerije',
      es: 'Argelia',
      fr: 'Algérie',
      hu: 'Algéria',
      ca: 'Algèria',
    },
    topLevelDomain: ['.dz'],
    alpha2Code: 'DZ',
    alpha3Code: 'DZA',
    callingCodes: ['213'],
    capital: 'Algiers',
    altSpellings: ['DZ', 'Dzayer', 'Algérie'],
    region: 'Africa'
  },
  {
    name: 'American Samoa',
    translatedName: {
      en: 'American Samoa',
      de: 'Amerikanischen Samoa-Inseln',
      nl: 'Amerikaans Samoa',
      es: 'Samoa Americana',
      fr: 'Samoa américaine',
      hu: 'Amerikai Szamoa',
      ca: 'American Samoa',
    },
    topLevelDomain: ['.as'],
    alpha2Code: 'AS',
    alpha3Code: 'ASM',
    callingCodes: ['1'],
    capital: 'Pago Pago',
    altSpellings: ['AS', 'Amerika Sāmoa', 'Amelika Sāmoa', 'Sāmoa Amelika'],
    region: 'Oceania'
  },
  {
    name: 'Andorra',
    translatedName: {
      en: 'Andorra',
      de: 'Andorra',
      nl: 'Andorra',
      es: 'Andorra',
      fr: 'Andorre',
      hu: 'Andorra',
      ca: 'Andorra',
    },
    topLevelDomain: ['.ad'],
    alpha2Code: 'AD',
    alpha3Code: 'AND',
    callingCodes: ['376'],
    capital: 'Andorra la Vella',
    altSpellings: ['AD', 'Principality of Andorra', "Principat d'Andorra"],
    region: 'Europe'
  },
  {
    name: 'Angola',
    translatedName: {
      en: 'Angola',
      de: 'Angola',
      nl: 'Angola',
      es: 'Angola',
      fr: 'Angola',
      hu: 'Angola',
      ca: 'Angola',
    },
    topLevelDomain: ['.ao'],
    alpha2Code: 'AO',
    alpha3Code: 'AGO',
    callingCodes: ['244'],
    capital: 'Luanda',
    altSpellings: ['AO', 'República de Angola', "ʁɛpublika de an'ɡɔla"],
    region: 'Africa'
  },
  {
    name: 'Anguilla',
    translatedName: {
      en: 'Anguilla',
      de: 'Anguilla',
      nl: 'Anguilla',
      es: 'Anguila',
      fr: 'Anguille',
      hu: 'Anguilla',
      ca: 'Anguilla',
    },
    topLevelDomain: ['.ai'],
    alpha2Code: 'AI',
    alpha3Code: 'AIA',
    callingCodes: ['1'],
    capital: 'The Valley',
    altSpellings: ['AI'],
    region: 'Americas'
  },
  {
    name: 'Antarctica',
    translatedName: {
      en: 'Antarctica',
      de: 'Antarktis',
      nl: 'Antarctica',
      es: 'Antártida',
      fr: 'Antarctique',
      hu: 'Antarktisz',
      ca: 'Antàrtida',
    },
    topLevelDomain: ['.aq'],
    alpha2Code: 'AQ',
    alpha3Code: 'ATA',
    callingCodes: ['672'],
    capital: '',
    altSpellings: [],
    region: 'Polar'
  },
  {
    name: 'Antigua and Barbuda',
    translatedName: {
      en: 'Antigua and Barbuda',
      de: 'Antigua und Barbuda',
      nl: 'Antigua en Barbuda',
      es: 'Antigua y Barbuda',
      fr: 'Antigua-et-Barbuda',
      hu: 'Antigua és Barbuda',
      ca: 'Antigua i Barbuda',
    },
    topLevelDomain: ['.ag'],
    alpha2Code: 'AG',
    alpha3Code: 'ATG',
    callingCodes: ['1'],
    capital: "Saint John's",
    altSpellings: ['AG'],
    region: 'Americas'
  },
  {
    name: 'Argentina',
    translatedName: {
      en: 'Argentina',
      de: 'Argentinien',
      nl: 'Argentinië',
      es: 'Argentina',
      fr: 'Argentine',
      hu: 'Argentína',
      ca: 'Argentina',
    },
    topLevelDomain: ['.ar'],
    alpha2Code: 'AR',
    alpha3Code: 'ARG',
    callingCodes: ['54'],
    capital: 'Buenos Aires',
    altSpellings: ['AR', 'Argentine Republic', 'República Argentina'],
    region: 'Americas'
  },
  {
    name: 'Armenia',
    translatedName: {
      en: 'Armenia',
      de: 'Armenien',
      nl: 'Armenië',
      es: 'Armenia',
      fr: 'Arménie',
      hu: 'Örményország',
      ca: 'Armènia',
    },
    topLevelDomain: ['.am'],
    alpha2Code: 'AM',
    alpha3Code: 'ARM',
    callingCodes: ['374'],
    capital: 'Yerevan',
    altSpellings: ['AM', 'Hayastan', 'Republic of Armenia', 'Հայաստանի Հանրապետություն'],
    region: 'Asia'
  },
  {
    name: 'Aruba',
    translatedName: {
      en: 'Aruba',
      de: 'Aruba',
      nl: 'Aruba',
      es: 'Aruba',
      fr: 'Aruba',
      hu: 'Aruba',
      ca: 'Aruba',
    },
    topLevelDomain: ['.aw'],
    alpha2Code: 'AW',
    alpha3Code: 'ABW',
    callingCodes: ['297'],
    capital: 'Oranjestad',
    altSpellings: ['AW'],
    region: 'Americas'
  },
  {
    name: 'Australia',
    translatedName: {
      en: 'Australia',
      de: 'Australien',
      nl: 'Australië',
      es: 'Australia',
      fr: 'Australie',
      hu: 'Ausztrália',
      ca: 'Austràlia',
    },
    topLevelDomain: ['.au'],
    alpha2Code: 'AU',
    alpha3Code: 'AUS',
    callingCodes: ['61'],
    capital: 'Canberra',
    altSpellings: ['AU'],
    region: 'Oceania'
  },
  {
    name: 'Austria',
    translatedName: {
      en: 'Austria',
      de: 'Österreich',
      nl: 'Oostenrijk',
      es: 'Austria',
      fr: "L'Autriche",
      hu: 'Ausztria',
      ca: 'Àustria',
    },
    topLevelDomain: ['.at'],
    alpha2Code: 'AT',
    alpha3Code: 'AUT',
    callingCodes: ['43'],
    capital: 'Vienna',
    altSpellings: ['AT', 'Österreich', 'Osterreich', 'Oesterreich'],
    region: 'Europe'
  },
  {
    name: 'Azerbaijan',
    translatedName: {
      en: 'Azerbaijan',
      de: 'Aserbaidschan',
      nl: 'Azerbeidzjan',
      es: 'Azerbaiyán',
      fr: 'Azerbaïdjan',
      hu: 'Azerbajdzsán',
      ca: 'Azerbaidjan',
    },
    topLevelDomain: ['.az'],
    alpha2Code: 'AZ',
    alpha3Code: 'AZE',
    callingCodes: ['994'],
    capital: 'Baku',
    altSpellings: ['AZ', 'Republic of Azerbaijan', 'Azərbaycan Respublikası'],
    region: 'Asia'
  },
  {
    name: 'Bahamas',
    translatedName: {
      en: 'Bahamas',
      de: 'Die Bahamas',
      nl: 'Bahamas',
      es: 'Bahamas',
      fr: 'Bahamas',
      hu: 'Bahama-szigetek',
      ca: 'Les Bahames',
    },
    topLevelDomain: ['.bs'],
    alpha2Code: 'BS',
    alpha3Code: 'BHS',
    callingCodes: ['1'],
    capital: 'Nassau',
    altSpellings: ['BS', 'Commonwealth of the Bahamas'],
    region: 'Americas'
  },
  {
    name: 'Bahrain',
    translatedName: {
      en: 'Bahrain',
      de: 'Bahrain',
      nl: 'Bahrein',
      es: 'Bahrein',
      fr: 'Bahreïn',
      hu: 'Bahrein',
      ca: 'Bahrain',
    },
    topLevelDomain: ['.bh'],
    alpha2Code: 'BH',
    alpha3Code: 'BHR',
    callingCodes: ['973'],
    capital: 'Manama',
    altSpellings: ['BH', 'Kingdom of Bahrain', 'Mamlakat al-Baḥrayn'],
    region: 'Asia'
  },
  {
    name: 'Bangladesh',
    translatedName: {
      en: 'Bangladesh',
      de: 'Bangladesch',
      nl: 'Bangladesh',
      es: 'Bangladesh',
      fr: 'Bangladesh',
      hu: 'Banglades',
      ca: 'Bangla Desh',
    },
    topLevelDomain: ['.bd'],
    alpha2Code: 'BD',
    alpha3Code: 'BGD',
    callingCodes: ['880'],
    capital: 'Dhaka',
    altSpellings: ['BD', "People's Republic of Bangladesh", 'Gônôprôjatôntri Bangladesh'],
    region: 'Asia'
  },
  {
    name: 'Barbados',
    translatedName: {
      en: 'Barbados',
      de: 'Barbados',
      nl: 'Barbados',
      es: 'Barbados',
      fr: 'Barbade',
      hu: 'Barbados',
      ca: 'Barbados',
    },
    topLevelDomain: ['.bb'],
    alpha2Code: 'BB',
    alpha3Code: 'BRB',
    callingCodes: ['1'],
    capital: 'Bridgetown',
    altSpellings: ['BB'],
    region: 'Americas'
  },
  {
    name: 'Belarus',
    translatedName: {
      en: 'Belarus',
      de: 'Weißrussland',
      nl: 'Wit -Rusland',
      es: 'Bielorrusia',
      fr: 'Biélorussie',
      hu: 'Fehéroroszország',
      ca: 'Bielorúss',
    },
    topLevelDomain: ['.by'],
    alpha2Code: 'BY',
    alpha3Code: 'BLR',
    callingCodes: ['375'],
    capital: 'Minsk',
    altSpellings: [
      'BY',
      'Bielaruś',
      'Republic of Belarus',
      'Белоруссия',
      'Республика Беларусь',
      'Belorussiya',
      'Respublika Belarus’'
    ],
    region: 'Europe'
  },
  {
    name: 'Belgium',
    translatedName: {
      en: 'Belgium',
      de: 'Belgien',
      nl: 'België',
      es: 'Bélgica',
      fr: 'Belgique',
      hu: 'Belgium',
      ca: 'Bèlgica',
    },
    topLevelDomain: ['.be'],
    alpha2Code: 'BE',
    alpha3Code: 'BEL',
    callingCodes: ['32'],
    capital: 'Brussels',
    altSpellings: [
      'BE',
      'België',
      'Belgie',
      'Belgien',
      'Belgique',
      'Kingdom of Belgium',
      'Koninkrijk België',
      'Royaume de Belgique',
      'Königreich Belgien'
    ],
    region: 'Europe'
  },
  {
    name: 'Belize',
    translatedName: {
      en: 'Belize',
      de: 'Belize',
      nl: 'Belize',
      es: 'Belice',
      fr: 'Belize',
      hu: 'Belize',
      ca: 'Belize',
    },
    topLevelDomain: ['.bz'],
    alpha2Code: 'BZ',
    alpha3Code: 'BLZ',
    callingCodes: ['501'],
    capital: 'Belmopan',
    altSpellings: ['BZ'],
    region: 'Americas'
  },
  {
    name: 'Benin',
    translatedName: {
      en: 'Benin',
      de: 'Benin',
      nl: 'Benin',
      es: 'Benin',
      fr: 'Bénin',
      hu: 'Benin',
      ca: 'Benín',
    },
    topLevelDomain: ['.bj'],
    alpha2Code: 'BJ',
    alpha3Code: 'BEN',
    callingCodes: ['229'],
    capital: 'Porto-Novo',
    altSpellings: ['BJ', 'Republic of Benin', 'République du Bénin'],
    region: 'Africa'
  },
  {
    name: 'Bermuda',
    translatedName: {
      en: 'Bermuda',
      de: 'Bermuda',
      nl: 'Bermuda',
      es: 'Islas Bermudas',
      fr: 'Bermuda',
      hu: 'Bermuda',
      ca: 'Bermuda',
    },
    topLevelDomain: ['.bm'],
    alpha2Code: 'BM',
    alpha3Code: 'BMU',
    callingCodes: ['1'],
    capital: 'Hamilton',
    altSpellings: ['BM', 'The Islands of Bermuda', 'The Bermudas', 'Somers Isles'],
    region: 'Americas'
  },
  {
    name: 'Bhutan',
    translatedName: {
      en: 'Bhutan',
      de: 'Bhutan',
      nl: 'Bhutan',
      es: 'Bhután',
      fr: 'Bhoutan',
      hu: 'Bhután',
      ca: 'Bhutan',
    },
    topLevelDomain: ['.bt'],
    alpha2Code: 'BT',
    alpha3Code: 'BTN',
    callingCodes: ['975'],
    capital: 'Thimphu',
    altSpellings: ['BT', 'Kingdom of Bhutan'],
    region: 'Asia'
  },
  {
    name: 'Bolivia',
    translatedName: {
      en: 'Bolivia',
      de: 'Bolivien',
      nl: 'Bolivia',
      es: 'Bolivia',
      fr: 'Bolivie',
      hu: 'Bolívia',
      ca: 'Bolívia',
    },
    topLevelDomain: ['.bo'],
    alpha2Code: 'BO',
    alpha3Code: 'BOL',
    callingCodes: ['591'],
    capital: 'Sucre',
    altSpellings: [
      'BO',
      'Buliwya',
      'Wuliwya',
      'Plurinational State of Bolivia',
      'Estado Plurinacional de Bolivia',
      'Buliwya Mamallaqta',
      'Wuliwya Suyu',
      'Tetã Volívia'
    ],
    region: 'Americas'
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    translatedName: {
      en: 'Bonaire, Sint Eustatius and Saba',
      de: 'Bonaire, Sint Eustatius und Saba',
      nl: 'Bonaire, Sint Eustatius en Saba',
      es: 'Bonaire, Sint Eustatius y Saba',
      fr: 'Bonaire, Sint Eustatius et Saba',
      hu: 'Bonaire, Sint Eustatius és Saba',
      ca: 'Bonaire, Sint Eustatius i Saba',
    },
    topLevelDomain: ['.an', '.nl'],
    alpha2Code: 'BQ',
    alpha3Code: 'BES',
    callingCodes: ['599'],
    capital: 'Kralendijk',
    altSpellings: ['BQ', 'Boneiru'],
    region: 'Americas'
  },
  {
    name: 'Bosnia and Herzegovina',
    translatedName: {
      en: 'Bosnia and Herzegovina',
      de: 'Bosnien und Herzegowina',
      nl: 'Bosnië-Herzegovina',
      es: 'Bosnia-Herzegovina',
      fr: 'Bosnie Herzégovine',
      hu: 'Bosznia-Hercegovina',
      ca: 'Bòsnia i Hercegovina',
    },
    topLevelDomain: ['.ba'],
    alpha2Code: 'BA',
    alpha3Code: 'BIH',
    callingCodes: ['387'],
    capital: 'Sarajevo',
    altSpellings: ['BA', 'Bosnia-Herzegovina', 'Босна и Херцеговина'],
    region: 'Europe'
  },
  {
    name: 'Botswana',
    translatedName: {
      en: 'Botswana',
      de: 'Botswana',
      nl: 'Botswana',
      es: 'Botswana',
      fr: 'Botwana',
      hu: 'Botswana',
      ca: 'Botswana',
    },
    topLevelDomain: ['.bw'],
    alpha2Code: 'BW',
    alpha3Code: 'BWA',
    callingCodes: ['267'],
    capital: 'Gaborone',
    altSpellings: ['BW', 'Republic of Botswana', 'Lefatshe la Botswana'],
    region: 'Africa'
  },
  {
    name: 'Bouvet Island',
    translatedName: {
      en: 'Bouvet Island',
      de: 'Bouvetinseln',
      nl: 'Bouvet -eiland',
      es: 'Isla bouvet',
      fr: 'Île Bouvet',
      hu: 'Bouvet-sziget',
      ca: 'Illa de Bouvet',
    },
    topLevelDomain: ['.bv'],
    alpha2Code: 'BV',
    alpha3Code: 'BVT',
    callingCodes: ['47'],
    capital: '',
    altSpellings: ['BV', 'Bouvetøya', 'Bouvet-øya'],
    region: ''
  },
  {
    name: 'Brazil',
    translatedName: {
      en: 'Brazil',
      de: 'Brasilien',
      nl: 'Brazilië',
      es: 'Brasil',
      fr: 'Brésil',
      hu: 'Brazília',
      ca: 'Brasil',
    },
    topLevelDomain: ['.br'],
    alpha2Code: 'BR',
    alpha3Code: 'BRA',
    callingCodes: ['55'],
    capital: 'Brasília',
    altSpellings: [
      'BR',
      'Brasil',
      'Federative Republic of Brazil',
      'República Federativa do Brasil'
    ],
    region: 'Americas'
  },
  {
    name: 'British Indian Ocean Territory',
    translatedName: {
      en: 'British Indian Ocean Territory',
      de: 'Britisches Territorium des Indischen Ozeans',
      nl: 'Brits-Indisch Oceaan Gebied',
      es: 'Territorio Británico del Océano Índico',
      fr: "Territoire Britannique de l'océan Indien",
      hu: 'Brit Indiai-Óceán Területe',
      ca: 'Territori britànic de l’oceà Índic',
    },
    topLevelDomain: ['.io'],
    alpha2Code: 'IO',
    alpha3Code: 'IOT',
    callingCodes: ['246'],
    capital: 'Diego Garcia',
    altSpellings: ['IO'],
    region: 'Africa'
  },
  {
    name: 'United States Minor Outlying Islands',
    translatedName: {
      en: 'United States Minor Outlying Islands',
      de: 'Kleinere abgelegene Inseln der Vereinigten Staaten',
      nl: 'Kleine Afgelegen Eilanden van de Verenigde Staten',
      es: 'Islas Menores de los Estados Unidos.',
      fr: 'Îles Mineures Éloignées des États-Unis',
      hu: 'Az Amerikai Egyesült Államok külbirtokai',
      ca: 'Illes perifèriques menors dels Estats Units',
    },
    topLevelDomain: ['.us'],
    alpha2Code: 'UM',
    alpha3Code: 'UMI',
    callingCodes: ['1'],
    capital: '',
    altSpellings: ['UM'],
    region: 'Americas'
  },
  {
    name: 'Virgin Islands (British)',
    translatedName: {
      en: 'Virgin Islands (British)',
      de: 'Jungferninseln, (Britisch)',
      nl: 'Maagdeneilanden (Brits)',
      es: 'Islas Vírgenes (Británicas)',
      fr: 'Îles Vierges (Britanniques)',
      hu: 'Virgin-szigetek (Brit)',
      ca: 'Illes Verges (britàniques)',
    },
    topLevelDomain: ['.vg'],
    alpha2Code: 'VG',
    alpha3Code: 'VGB',
    callingCodes: ['1'],
    capital: 'Road Town',
    altSpellings: ['VG'],
    region: 'Americas'
  },
  {
    name: 'Virgin Islands (U.S.)',
    translatedName: {
      en: 'Virgin Islands (U.S.)',
      de: 'Jungferninseln (USA)',
      nl: 'Maagdeneilanden (VS)',
      es: 'Islas Vírgenes (EE. UU.)',
      fr: 'Îles Vierges (États-Unis)',
      hu: 'Virgin-szigetek (USA)',
      ca: 'Illes Verges (EUA)',
    },
    topLevelDomain: ['.vi'],
    alpha2Code: 'VI',
    alpha3Code: 'VIR',
    callingCodes: ['1 340'],
    capital: 'Charlotte Amalie',
    altSpellings: ['VI', 'USVI', 'American Virgin Islands', 'U.S. Virgin Islands'],
    region: 'Americas'
  },
  {
    name: 'Brunei Darussalam',
    translatedName: {
      en: 'Brunei Darussalam',
      de: 'Brunei Darussalam',
      nl: 'Brunei Darussalam',
      es: 'Brunei Darussalam',
      fr: 'Brunei Darussalam',
      hu: 'Brunei Darussalam',
      ca: 'Brunei Darussalam',
    },
    topLevelDomain: ['.bn'],
    alpha2Code: 'BN',
    alpha3Code: 'BRN',
    callingCodes: ['673'],
    capital: 'Bandar Seri Begawan',
    altSpellings: ['BN', 'Nation of Brunei', ' the Abode of Peace'],
    region: 'Asia'
  },
  {
    name: 'Bulgaria',
    translatedName: {
      en: 'Bulgaria',
      de: 'Bulgarien',
      nl: 'Bulgarije',
      es: 'Bulgaria',
      fr: 'Bulgarie',
      hu: 'Bulgária',
      ca: 'Bulgària',
    },
    topLevelDomain: ['.bg'],
    alpha2Code: 'BG',
    alpha3Code: 'BGR',
    callingCodes: ['359'],
    capital: 'Sofia',
    altSpellings: ['BG', 'Republic of Bulgaria', 'Република България'],
    region: 'Europe'
  },
  {
    name: 'Burkina Faso',
    translatedName: {
      en: 'Burkina Faso',
      de: 'Burkina Faso',
      nl: 'Burkina Faso',
      es: 'Burkina Faso',
      fr: 'Burkina Faso',
      hu: 'Burkina Faso',
      ca: 'Burkina Faso',
    },
    topLevelDomain: ['.bf'],
    alpha2Code: 'BF',
    alpha3Code: 'BFA',
    callingCodes: ['226'],
    capital: 'Ouagadougou',
    altSpellings: ['BF'],
    region: 'Africa'
  },
  {
    name: 'Burundi',
    translatedName: {
      en: 'Burundi',
      de: 'Burundi',
      nl: 'Burundi',
      es: 'Burundi',
      fr: 'Burundi',
      hu: 'Burundi',
      ca: 'Burundi',
    },
    topLevelDomain: ['.bi'],
    alpha2Code: 'BI',
    alpha3Code: 'BDI',
    callingCodes: ['257'],
    capital: 'Bujumbura',
    altSpellings: ['BI', 'Republic of Burundi', "Republika y'Uburundi", 'République du Burundi'],
    region: 'Africa'
  },
  {
    name: 'Cambodia',
    translatedName: {
      en: 'Cambodia',
      de: 'Kambodscha',
      nl: 'Cambodja',
      es: 'Camboya',
      fr: 'Cambodge',
      hu: 'Kambodzsa',
      ca: 'Cambodja',
    },
    topLevelDomain: ['.kh'],
    alpha2Code: 'KH',
    alpha3Code: 'KHM',
    callingCodes: ['855'],
    capital: 'Phnom Penh',
    altSpellings: ['KH', 'Kingdom of Cambodia'],
    region: 'Asia'
  },
  {
    name: 'Cameroon',
    translatedName: {
      en: 'Cameroon',
      de: 'Kamerun',
      nl: 'Kameroen',
      es: 'Camerún',
      fr: 'Cameroun',
      hu: 'Kamerun',
      ca: 'Camerun',
    },
    topLevelDomain: ['.cm'],
    alpha2Code: 'CM',
    alpha3Code: 'CMR',
    callingCodes: ['237'],
    capital: 'Yaoundé',
    altSpellings: ['CM', 'Republic of Cameroon', 'République du Cameroun'],
    region: 'Africa'
  },
  {
    name: 'Canada',
    translatedName: {
      en: 'Canada',
      de: 'Kanada',
      nl: 'Canada',
      es: 'Canadá',
      fr: 'Canada',
      hu: 'Kanada',
      ca: 'Canadà',
    },
    topLevelDomain: ['.ca'],
    alpha2Code: 'CA',
    alpha3Code: 'CAN',
    callingCodes: ['1'],
    capital: 'Ottawa',
    altSpellings: ['CA'],
    region: 'Americas'
  },
  {
    name: 'Cabo Verde',
    translatedName: {
      en: 'Cabo Verde',
      de: 'Cabo Verde',
      nl: 'Cabo Verde',
      es: 'Cabo Verde',
      fr: 'Cabo Verde',
      hu: 'Cabo Verde',
      ca: 'Cabo Verde',
    },
    topLevelDomain: ['.cv'],
    alpha2Code: 'CV',
    alpha3Code: 'CPV',
    callingCodes: ['238'],
    capital: 'Praia',
    altSpellings: ['CV', 'Republic of Cabo Verde', 'República de Cabo Verde'],
    region: 'Africa'
  },
  {
    name: 'Cayman Islands',
    translatedName: {
      en: 'Cayman Islands',
      de: 'Caymaninseln',
      nl: 'Kaaiman Eilanden',
      es: 'Islas Caimán',
      fr: 'Îles Caïmans',
      hu: 'Kajmán-szigetek',
      ca: 'Illes Caiman',
    },
    topLevelDomain: ['.ky'],
    alpha2Code: 'KY',
    alpha3Code: 'CYM',
    callingCodes: ['1'],
    capital: 'George Town',
    altSpellings: ['KY'],
    region: 'Americas'
  },
  {
    name: 'Central African Republic',
    translatedName: {
      en: 'Central African Republic',
      de: 'Zentralafrikanische Republik',
      nl: 'Centraal Afrikaanse Republiek',
      es: 'República Centroafricana',
      fr: 'République centrafricaine',
      hu: 'Közép-afrikai Köztársaság',
      ca: 'República Centreafricana',
    },
    topLevelDomain: ['.cf'],
    alpha2Code: 'CF',
    alpha3Code: 'CAF',
    callingCodes: ['236'],
    capital: 'Bangui',
    altSpellings: ['CF', 'Central African Republic', 'République centrafricaine'],
    region: 'Africa'
  },
  {
    name: 'Chad',
    translatedName: {
      en: 'Chad',
      de: 'Tschad',
      nl: 'Tsjaad',
      es: 'Chad',
      fr: 'Tchad',
      hu: 'Csád',
      ca: 'Txad',
    },
    topLevelDomain: ['.td'],
    alpha2Code: 'TD',
    alpha3Code: 'TCD',
    callingCodes: ['235'],
    capital: "N'Djamena",
    altSpellings: ['TD', 'Tchad', 'Republic of Chad', 'République du Tchad'],
    region: 'Africa'
  },
  {
    name: 'Chile',
    translatedName: {
      en: 'Chile',
      de: 'Chile',
      nl: 'Chili',
      es: 'Chile',
      fr: 'Chili',
      hu: 'Chile',
      ca: 'Xile',
    },
    topLevelDomain: ['.cl'],
    alpha2Code: 'CL',
    alpha3Code: 'CHL',
    callingCodes: ['56'],
    capital: 'Santiago',
    altSpellings: ['CL', 'Republic of Chile', 'República de Chile'],
    region: 'Americas'
  },
  {
    name: 'China',
    translatedName: {
      en: 'China',
      de: 'China',
      nl: 'China',
      es: 'Porcelana',
      fr: 'Chine',
      hu: 'Kína',
      ca: 'Xina',
    },
    topLevelDomain: ['.cn'],
    alpha2Code: 'CN',
    alpha3Code: 'CHN',
    callingCodes: ['86'],
    capital: 'Beijing',
    altSpellings: [
      'CN',
      'Zhōngguó',
      'Zhongguo',
      'Zhonghua',
      "People's Republic of China",
      '中华人民共和国',
      'Zhōnghuá Rénmín Gònghéguó'
    ],
    region: 'Asia'
  },
  {
    name: 'Christmas Island',
    translatedName: {
      en: 'Christmas Island',
      de: 'Weihnachtsinsel',
      nl: 'Kersteiland',
      es: 'Isla de Navidad',
      fr: "L'île de noël",
      hu: 'Karácsony-sziget',
      ca: 'Illa de Nadal',
    },
    topLevelDomain: ['.cx'],
    alpha2Code: 'CX',
    alpha3Code: 'CXR',
    callingCodes: ['61'],
    capital: 'Flying Fish Cove',
    altSpellings: ['CX', 'Territory of Christmas Island'],
    region: 'Oceania'
  },
  {
    name: 'Cocos (Keeling) Islands',
    translatedName: {
      en: 'Cocos (Keeling) Islands',
      de: 'Cocos (Keeling) Inseln',
      nl: 'Cocos (keeling) eilanden',
      es: 'Islas Cocos (Keeling)',
      fr: 'Îles de cocos (keelling)',
      hu: 'Cocos (Keeling) szigetek',
      ca: 'Illes Cocos (Keeling)',
    },
    topLevelDomain: ['.cc'],
    alpha2Code: 'CC',
    alpha3Code: 'CCK',
    callingCodes: ['61'],
    capital: 'West Island',
    altSpellings: ['CC', 'Territory of the Cocos (Keeling) Islands', 'Keeling Islands'],
    region: 'Oceania'
  },
  {
    name: 'Colombia',
    translatedName: {
      en: 'Colombia',
      de: 'Kolumbien',
      nl: 'Colombia',
      es: 'Colombia',
      fr: 'Colombie',
      hu: 'Kolumbia',
      ca: 'Colòmbia',
    },
    topLevelDomain: ['.co'],
    alpha2Code: 'CO',
    alpha3Code: 'COL',
    callingCodes: ['57'],
    capital: 'Bogotá',
    altSpellings: ['CO', 'Republic of Colombia', 'República de Colombia'],
    region: 'Americas'
  },
  {
    name: 'Comoros',
    translatedName: {
      en: 'Comoros',
      de: 'Komoren',
      nl: 'Comoros',
      es: 'Comoras',
      fr: 'Comores',
      hu: 'Comore-szigetek',
      ca: 'Comores',
    },
    topLevelDomain: ['.km'],
    alpha2Code: 'KM',
    alpha3Code: 'COM',
    callingCodes: ['269'],
    capital: 'Moroni',
    altSpellings: [
      'KM',
      'Union of the Comoros',
      'Union des Comores',
      'Udzima wa Komori',
      'al-Ittiḥād al-Qumurī'
    ],
    region: 'Africa'
  },
  {
    name: 'Congo, Republic',
    translatedName: {
      en: 'Congo, Republic',
      de: 'Kongo, Replublik',
      nl: 'Congo, Republiek',
      es: 'Congo, República',
      fr: 'Congo, République',
      hu: 'Kongói Köztársaság',
      ca: 'Congo, República',
    },
    topLevelDomain: ['.cg'],
    alpha2Code: 'CG',
    alpha3Code: 'COG',
    callingCodes: ['242'],
    capital: 'Brazzaville',
    altSpellings: ['CG', 'Congo-Brazzaville'],
    region: 'Africa'
  },
  {
    name: 'Congo, Democratic Republic',
    translatedName: {
      en: 'Congo, Democratic Republic',
      de: 'Kongo, Demokratische Republik',
      nl: 'Congo, Democratische Republiek',
      es: 'Congo, República Democrática',
      fr: 'Congo, République démocratique',
      hu: 'Kongói Demokratikus Köztársaság',
      ca: 'Congo, República Democràtica',
    },
    topLevelDomain: ['.cd'],
    alpha2Code: 'CD',
    alpha3Code: 'COD',
    callingCodes: ['243'],
    capital: 'Kinshasa',
    altSpellings: ['CD', 'DR Congo', 'Congo-Kinshasa', 'DRC'],
    region: 'Africa'
  },
  {
    name: 'Cook Islands',
    translatedName: {
      en: 'Cook Islands',
      de: 'Cookinseln',
      nl: 'Kook eilanden',
      es: 'Islas Cook',
      fr: 'les Îles Cook',
      hu: 'Cook-szigetek',
      ca: 'Illes Cook',
    },
    topLevelDomain: ['.ck'],
    alpha2Code: 'CK',
    alpha3Code: 'COK',
    callingCodes: ['682'],
    capital: 'Avarua',
    altSpellings: ['CK', "Kūki 'Āirani"],
    region: 'Oceania'
  },
  {
    name: 'Costa Rica',
    translatedName: {
      en: 'Costa Rica',
      de: 'Costa Rica',
      nl: 'Costa Rica',
      es: 'Costa Rica',
      fr: 'Costa Rica',
      hu: 'Costa Rica',
      ca: 'Costa Rica',
    },
    topLevelDomain: ['.cr'],
    alpha2Code: 'CR',
    alpha3Code: 'CRI',
    callingCodes: ['506'],
    capital: 'San José',
    altSpellings: ['CR', 'Republic of Costa Rica', 'República de Costa Rica'],
    region: 'Americas'
  },
  {
    name: 'Croatia',
    translatedName: {
      en: 'Croatia',
      de: 'Kroatien',
      nl: 'Kroatië',
      es: 'Croacia',
      fr: 'Croatie',
      hu: 'Horvátország',
      ca: 'Croàcia',
    },
    topLevelDomain: ['.hr'],
    alpha2Code: 'HR',
    alpha3Code: 'HRV',
    callingCodes: ['385'],
    capital: 'Zagreb',
    altSpellings: ['HR', 'Hrvatska', 'Republic of Croatia', 'Republika Hrvatska'],
    region: 'Europe'
  },
  {
    name: 'Cuba',
    translatedName: {
      en: 'Cuba',
      de: 'Kuba',
      nl: 'Cuba',
      es: 'Cuba',
      fr: 'Cuba',
      hu: 'Kuba',
      ca: 'Cuba',
    },
    topLevelDomain: ['.cu'],
    alpha2Code: 'CU',
    alpha3Code: 'CUB',
    callingCodes: ['53'],
    capital: 'Havana',
    altSpellings: ['CU', 'Republic of Cuba', 'República de Cuba'],
    region: 'Americas'
  },
  {
    name: 'Curaçao',
    translatedName: {
      en: 'Curaçao',
      de: 'Curacao',
      nl: 'Curacao',
      es: 'Curaçao',
      fr: 'Curacao',
      hu: 'Curaçao',
      ca: 'Curaçao',
    },
    topLevelDomain: ['.cw'],
    alpha2Code: 'CW',
    alpha3Code: 'CUW',
    callingCodes: ['599'],
    capital: 'Willemstad',
    altSpellings: ['CW', 'Curacao', 'Kòrsou', 'Country of Curaçao', 'Land Curaçao', 'Pais Kòrsou'],
    region: 'Americas'
  },
  {
    name: 'Cyprus',
    translatedName: {
      en: 'Cyprus',
      de: 'Zypern',
      nl: 'Cyprus',
      es: 'Chipre',
      fr: 'Chypre',
      hu: 'Ciprus',
      ca: 'Xipre',
    },
    topLevelDomain: ['.cy'],
    alpha2Code: 'CY',
    alpha3Code: 'CYP',
    callingCodes: ['357'],
    capital: 'Nicosia',
    altSpellings: [
      'CY',
      'Kýpros',
      'Kıbrıs',
      'Republic of Cyprus',
      'Κυπριακή Δημοκρατία',
      'Kıbrıs Cumhuriyeti'
    ],
    region: 'Europe'
  },
  {
    name: 'Czech Republic',
    translatedName: {
      en: 'Czech Republic',
      de: 'Tschechische Republik',
      nl: 'Tsjechische Republiek',
      es: 'República Checa',
      fr: 'République tchèque',
      hu: 'Csehország',
      ca: 'República Txeca',
    },
    topLevelDomain: ['.cz'],
    alpha2Code: 'CZ',
    alpha3Code: 'CZE',
    callingCodes: ['420'],
    capital: 'Prague',
    altSpellings: ['CZ', 'Česká republika', 'Česko'],
    region: 'Europe'
  },
  {
    name: 'Denmark',
    translatedName: {
      en: 'Denmark',
      de: 'Dänemark',
      nl: 'Denemarken',
      es: 'Dinamarca',
      fr: 'Danemark',
      hu: 'Dánia',
      ca: 'Dinamarca',
    },
    topLevelDomain: ['.dk'],
    alpha2Code: 'DK',
    alpha3Code: 'DNK',
    callingCodes: ['45'],
    capital: 'Copenhagen',
    altSpellings: ['DK', 'Danmark', 'Kingdom of Denmark', 'Kongeriget Danmark'],
    region: 'Europe'
  },
  {
    name: 'Djibouti',
    translatedName: {
      en: 'Djibouti',
      de: 'Dschibuti',
      nl: 'Djibouti',
      es: 'Yibouti',
      fr: 'Djibouti',
      hu: 'Dzsibuti',
      ca: 'Djibouti',
    },
    topLevelDomain: ['.dj'],
    alpha2Code: 'DJ',
    alpha3Code: 'DJI',
    callingCodes: ['253'],
    capital: 'Djibouti',
    altSpellings: [
      'DJ',
      'Jabuuti',
      'Gabuuti',
      'Republic of Djibouti',
      'République de Djibouti',
      'Gabuutih Ummuuno',
      'Jamhuuriyadda Jabuuti'
    ],
    region: 'Africa'
  },
  {
    name: 'Dominica',
    translatedName: {
      en: 'Dominica',
      de: 'Dominica',
      nl: 'Dominica',
      es: 'Dominica',
      fr: 'Dominique',
      hu: 'Dominika',
      ca: 'Dominica',
    },
    topLevelDomain: ['.dm'],
    alpha2Code: 'DM',
    alpha3Code: 'DMA',
    callingCodes: ['1'],
    capital: 'Roseau',
    altSpellings: ['DM', 'Dominique', 'Wai‘tu kubuli', 'Commonwealth of Dominica'],
    region: 'Americas'
  },
  {
    name: 'Dominican Republic',
    translatedName: {
      en: 'Dominican Republic',
      de: 'Dominikanische Republik',
      nl: 'Dominicaanse Republiek',
      es: 'República Dominicana',
      fr: 'République Dominicaine',
      hu: 'Dominikai Köztársaság',
      ca: 'República Dominicana',
    },
    topLevelDomain: ['.do'],
    alpha2Code: 'DO',
    alpha3Code: 'DOM',
    callingCodes: ['1'],
    capital: 'Santo Domingo',
    altSpellings: ['DO'],
    region: 'Americas'
  },
  {
    name: 'Ecuador',
    translatedName: {
      en: 'Ecuador',
      de: 'Ecuador',
      nl: 'Ecuador',
      es: 'Ecuador',
      fr: 'Equateur',
      hu: 'Ecuador',
      ca: 'Equador',
    },
    topLevelDomain: ['.ec'],
    alpha2Code: 'EC',
    alpha3Code: 'ECU',
    callingCodes: ['593'],
    capital: 'Quito',
    altSpellings: ['EC', 'Republic of Ecuador', 'República del Ecuador'],
    region: 'Americas'
  },
  {
    name: 'Egypt',
    translatedName: {
      en: 'Egypt',
      de: 'Ägypten',
      nl: 'Egypte',
      es: 'Egipto',
      fr: 'Egypte',
      hu: 'Egyiptom',
      ca: 'Egipte',
    },
    topLevelDomain: ['.eg'],
    alpha2Code: 'EG',
    alpha3Code: 'EGY',
    callingCodes: ['20'],
    capital: 'Cairo',
    altSpellings: ['EG', 'Arab Republic of Egypt'],
    region: 'Africa'
  },
  {
    name: 'El Salvador',
    translatedName: {
      en: 'El Salvador',
      de: 'El Salvador',
      nl: 'El Salvador',
      es: 'El Salvador',
      fr: 'Le Salvador',
      hu: 'El Salvador',
      ca: 'El Salvador',
    },
    topLevelDomain: ['.sv'],
    alpha2Code: 'SV',
    alpha3Code: 'SLV',
    callingCodes: ['503'],
    capital: 'San Salvador',
    altSpellings: ['SV', 'Republic of El Salvador', 'República de El Salvador'],
    region: 'Americas'
  },
  {
    name: 'Equatorial Guinea',
    translatedName: {
      en: 'Equatorial Guinea',
      de: 'Äquatorialguinea',
      nl: 'Equatoriaal-Guinea',
      es: 'Guinea Ecuatorial',
      fr: 'Guinée Équatoriale',
      hu: 'Egyenlítői-Guinea',
      ca: 'Guinea Equatorial',
    },
    topLevelDomain: ['.gq'],
    alpha2Code: 'GQ',
    alpha3Code: 'GNQ',
    callingCodes: ['240'],
    capital: 'Malabo',
    altSpellings: [
      'GQ',
      'Republic of Equatorial Guinea',
      'República de Guinea Ecuatorial',
      'République de Guinée équatoriale',
      'República da Guiné Equatorial'
    ],
    region: 'Africa'
  },
  {
    name: 'Eritrea',
    translatedName: {
      en: 'Eritrea',
      de: 'Eritrea',
      nl: 'Eritrea',
      es: 'Eritrea',
      fr: 'Érythrée',
      hu: 'Eritrea',
      ca: 'Eritrea',
    },
    topLevelDomain: ['.er'],
    alpha2Code: 'ER',
    alpha3Code: 'ERI',
    callingCodes: ['291'],
    capital: 'Asmara',
    altSpellings: [
      'ER',
      'State of Eritrea',
      'ሃገረ ኤርትራ',
      'Dawlat Iritriyá',
      'ʾErtrā',
      'Iritriyā',
      ''
    ],
    region: 'Africa'
  },
  {
    name: 'Estonia',
    translatedName: {
      en: 'Estonia',
      de: 'Estland',
      nl: 'Estland',
      es: 'Estonia',
      fr: 'Estonie',
      hu: 'Észtország',
      ca: 'Estònia',
    },
    topLevelDomain: ['.ee'],
    alpha2Code: 'EE',
    alpha3Code: 'EST',
    callingCodes: ['372'],
    capital: 'Tallinn',
    altSpellings: ['EE', 'Eesti', 'Republic of Estonia', 'Eesti Vabariik'],
    region: 'Europe'
  },
  {
    name: 'Ethiopia',
    translatedName: {
      en: 'Ethiopia',
      de: 'Äthiopien',
      nl: 'Ethiopië',
      es: 'Etiopía',
      fr: 'Ethiopie',
      hu: 'Etiópia',
      ca: 'Etiòpia',
    },
    topLevelDomain: ['.et'],
    alpha2Code: 'ET',
    alpha3Code: 'ETH',
    callingCodes: ['251'],
    capital: 'Addis Ababa',
    altSpellings: [
      'ET',
      'ʾĪtyōṗṗyā',
      'Federal Democratic Republic of Ethiopia',
      'የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ'
    ],
    region: 'Africa'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    translatedName: {
      en: 'Falkland Islands (Malvinas)',
      de: 'Falklandinseln (Malvinas)',
      nl: 'Falkland Islands (Malvinas)',
      es: 'Islas Malvinas',
      fr: 'Îles Falkland (Malvinas)',
      hu: 'Falkland-szigetek (Malvinák)',
      ca: 'Illes Falkland (Malvinas)',
    },
    topLevelDomain: ['.fk'],
    alpha2Code: 'FK',
    alpha3Code: 'FLK',
    callingCodes: ['500'],
    capital: 'Stanley',
    altSpellings: ['FK', 'Islas Malvinas'],
    region: 'Americas'
  },
  {
    name: 'Faroe Islands',
    translatedName: {
      en: 'Faroe Islands',
      de: 'Färöerinseln',
      nl: 'Faarseilanden',
      es: 'Islas Faroe',
      fr: 'Îles Féroé',
      hu: 'Faroe Szigetek',
      ca: 'Illes Faroe',
    },
    topLevelDomain: ['.fo'],
    alpha2Code: 'FO',
    alpha3Code: 'FRO',
    callingCodes: ['298'],
    capital: 'Tórshavn',
    altSpellings: ['FO', 'Føroyar', 'Færøerne'],
    region: 'Europe'
  },
  {
    name: 'Fiji',
    translatedName: {
      en: 'Fiji',
      de: 'Fidschi',
      nl: 'Fiji',
      es: 'Fiji',
      fr: 'Fidji',
      hu: 'Fidzsi-szigetek',
      ca: 'Fiji',
    },
    topLevelDomain: ['.fj'],
    alpha2Code: 'FJ',
    alpha3Code: 'FJI',
    callingCodes: ['679'],
    capital: 'Suva',
    altSpellings: ['FJ', 'Viti', 'Republic of Fiji', 'Matanitu ko Viti', 'Fijī Gaṇarājya'],
    region: 'Oceania'
  },
  {
    name: 'Finland',
    translatedName: {
      en: 'Finland',
      de: 'Finnland',
      nl: 'Finland',
      es: 'Finlandia',
      fr: 'Finlande',
      hu: 'Finnország',
      ca: 'Finlàndia',
    },
    topLevelDomain: ['.fi'],
    alpha2Code: 'FI',
    alpha3Code: 'FIN',
    callingCodes: ['358'],
    capital: 'Helsinki',
    altSpellings: ['FI', 'Suomi', 'Republic of Finland', 'Suomen tasavalta', 'Republiken Finland'],
    region: 'Europe'
  },
  {
    name: 'France',
    translatedName: {
      en: 'France',
      de: 'Frankreich',
      nl: 'Frankrijk',
      es: 'Francia',
      fr: 'France',
      hu: 'Franciaország',
      ca: 'França',
    },
    topLevelDomain: ['.fr'],
    alpha2Code: 'FR',
    alpha3Code: 'FRA',
    callingCodes: ['33'],
    capital: 'Paris',
    altSpellings: ['FR', 'French Republic', 'République française'],
    region: 'Europe'
  },
  {
    name: 'French Guiana',
    translatedName: {
      en: 'French Guiana',
      de: 'Französisch-Guayana',
      nl: 'Frans Guyana',
      es: 'Guayana Francesa',
      fr: 'Guyane Française',
      hu: 'Francia Guyana',
      ca: 'Guaiana Francesa',
    },
    topLevelDomain: ['.gf'],
    alpha2Code: 'GF',
    alpha3Code: 'GUF',
    callingCodes: ['594'],
    capital: 'Cayenne',
    altSpellings: ['GF', 'Guiana', 'Guyane'],
    region: 'Americas'
  },
  {
    name: 'French Polynesia',
    translatedName: {
      en: 'French Polynesia',
      de: 'Französisch Polynesien',
      nl: 'Frans-Polynesië',
      es: 'Polinesia Francés',
      fr: 'Polynésie Française',
      hu: 'Francia Polinézia',
      ca: 'Polinèsia Francesa',
    },
    topLevelDomain: ['.pf'],
    alpha2Code: 'PF',
    alpha3Code: 'PYF',
    callingCodes: ['689'],
    capital: 'Papeetē',
    altSpellings: ['PF', 'Polynésie française', 'French Polynesia', 'Pōrīnetia Farāni'],
    region: 'Oceania'
  },
  {
    name: 'French Southern Territories',
    translatedName: {
      en: 'French Southern Territories',
      de: 'Südfranzösische Territorien',
      nl: 'Franse Zuidelijke Gebieden',
      es: 'Territorios Franceses del Sur',
      fr: 'Territoires du Sud Français',
      hu: 'Francia Déli-területek',
      ca: 'Territoris del Sud Francesos',
    },
    topLevelDomain: ['.tf'],
    alpha2Code: 'TF',
    alpha3Code: 'ATF',
    callingCodes: ['262'],
    capital: 'Port-aux-Français',
    altSpellings: ['TF'],
    region: 'Africa'
  },
  {
    name: 'Gabon',
    translatedName: {
      en: 'Gabon',
      de: 'Gabun',
      nl: 'Gabon',
      es: 'Gabón',
      fr: 'Gabon',
      hu: 'Gabon',
      ca: 'Gabon',
    },
    topLevelDomain: ['.ga'],
    alpha2Code: 'GA',
    alpha3Code: 'GAB',
    callingCodes: ['241'],
    capital: 'Libreville',
    altSpellings: ['GA', 'Gabonese Republic', 'République Gabonaise'],
    region: 'Africa'
  },
  {
    name: 'Gambia, Republic',
    translatedName: {
      en: 'Gambia, Republic',
      de: 'Gambia, die Republik',
      nl: 'Gambia, Republiek',
      es: 'Gambia, República',
      fr: 'Gambie, République',
      hu: 'Gambiai Köztársaság',
      ca: 'Gàmbia, República',
    },
    topLevelDomain: ['.gm'],
    alpha2Code: 'GM',
    alpha3Code: 'GMB',
    callingCodes: ['220'],
    capital: 'Banjul',
    altSpellings: ['GM', 'Republic of the Gambia'],
    region: 'Africa'
  },
  {
    name: 'Georgia',
    translatedName: {
      en: 'Georgia',
      de: 'Georgien',
      nl: 'Georgië',
      es: 'Georgia',
      fr: 'Géorgie',
      hu: 'Grúzia',
      ca: 'Geòrgia',
    },
    topLevelDomain: ['.ge'],
    alpha2Code: 'GE',
    alpha3Code: 'GEO',
    callingCodes: ['995'],
    capital: 'Tbilisi',
    altSpellings: ['GE', 'Sakartvelo'],
    region: 'Asia'
  },
  {
    name: 'Germany',
    translatedName: {
      en: 'Germany',
      de: 'Deutschland',
      nl: 'Duitsland',
      es: 'Alemania',
      fr: 'Allemagne',
      hu: 'Németország',
      ca: 'Alemanya',
    },
    topLevelDomain: ['.de'],
    alpha2Code: 'DE',
    alpha3Code: 'DEU',
    callingCodes: ['49'],
    capital: 'Berlin',
    altSpellings: ['DE', 'Federal Republic of Germany', 'Bundesrepublik Deutschland'],
    region: 'Europe'
  },
  {
    name: 'Ghana',
    translatedName: {
      en: 'Ghana',
      de: 'Ghana',
      nl: 'Ghana',
      es: 'Ghana',
      fr: 'Ghana',
      hu: 'Ghána',
      ca: 'Ghana',
    },
    topLevelDomain: ['.gh'],
    alpha2Code: 'GH',
    alpha3Code: 'GHA',
    callingCodes: ['233'],
    capital: 'Accra',
    altSpellings: ['GH'],
    region: 'Africa'
  },
  {
    name: 'Gibraltar',
    translatedName: {
      en: 'Gibraltar',
      de: 'Gibraltar',
      nl: 'Gibraltar',
      es: 'Gibraltar',
      fr: 'Gibraltar',
      hu: 'Gibraltár',
      ca: 'Gibraltar',
    },
    topLevelDomain: ['.gi'],
    alpha2Code: 'GI',
    alpha3Code: 'GIB',
    callingCodes: ['350'],
    capital: 'Gibraltar',
    altSpellings: ['GI'],
    region: 'Europe'
  },
  {
    name: 'Greece',
    translatedName: {
      en: 'Greece',
      de: 'Griechenland',
      nl: 'Griekenland',
      es: 'Grecia',
      fr: 'Grèce',
      hu: 'Görögország',
      ca: 'Grècia',
    },
    topLevelDomain: ['.gr'],
    alpha2Code: 'GR',
    alpha3Code: 'GRC',
    callingCodes: ['30'],
    capital: 'Athens',
    altSpellings: ['GR', 'Elláda', 'Hellenic Republic', 'Ελληνική Δημοκρατία'],
    region: 'Europe'
  },
  {
    name: 'Greenland',
    translatedName: {
      en: 'Greenland',
      de: 'Grönland',
      nl: 'Groenland',
      es: 'Groenlandia',
      fr: 'Groenland',
      hu: 'Grönland',
      ca: 'Grecal',
    },
    topLevelDomain: ['.gl'],
    alpha2Code: 'GL',
    alpha3Code: 'GRL',
    callingCodes: ['299'],
    capital: 'Nuuk',
    altSpellings: ['GL', 'Grønland'],
    region: 'Americas'
  },
  {
    name: 'Grenada',
    translatedName: {
      en: 'Grenada',
      de: 'Grenada',
      nl: 'Grenada',
      es: 'Granada',
      fr: 'Grenade',
      hu: 'Grenada',
      ca: 'Grenada',
    },
    topLevelDomain: ['.gd'],
    alpha2Code: 'GD',
    alpha3Code: 'GRD',
    callingCodes: ['1'],
    capital: "St. George's",
    altSpellings: ['GD'],
    region: 'Americas'
  },
  {
    name: 'Guadeloupe',
    translatedName: {
      en: 'Guadeloupe',
      de: 'Guadeloupe',
      nl: 'Guadeloupe',
      es: 'Guadeloupo',
      fr: 'Guadeloup',
      hu: 'Guadeloupe',
      ca: 'Guadeloupe',
    },
    topLevelDomain: ['.gp'],
    alpha2Code: 'GP',
    alpha3Code: 'GLP',
    callingCodes: ['590'],
    capital: 'Basse-Terre',
    altSpellings: ['GP', 'Gwadloup'],
    region: 'Americas'
  },
  {
    name: 'Guam',
    translatedName: {
      en: 'Guam',
      de: 'Guam',
      nl: 'Guam',
      es: 'Guam',
      fr: 'Guam',
      hu: 'Guam',
      ca: 'Guam',
    },
    topLevelDomain: ['.gu'],
    alpha2Code: 'GU',
    alpha3Code: 'GUM',
    callingCodes: ['1'],
    capital: 'Hagåtña',
    altSpellings: ['GU', 'Guåhån'],
    region: 'Oceania'
  },
  {
    name: 'Guatemala',
    translatedName: {
      en: 'Guatemala',
      de: 'Guatemala',
      nl: 'Guatemala',
      es: 'Guatemala',
      fr: 'Guatemala',
      hu: 'Guatemala',
      ca: 'Guatemala',
    },
    topLevelDomain: ['.gt'],
    alpha2Code: 'GT',
    alpha3Code: 'GTM',
    callingCodes: ['502'],
    capital: 'Guatemala City',
    altSpellings: ['GT'],
    region: 'Americas'
  },
  {
    name: 'Guernsey',
    translatedName: {
      en: 'Guernsey',
      de: 'Guernsey',
      nl: 'Guernsey',
      es: 'Guernsey',
      fr: 'Guernesey',
      hu: 'Guernsey',
      ca: 'Guernsey',
    },
    topLevelDomain: ['.gg'],
    alpha2Code: 'GG',
    alpha3Code: 'GGY',
    callingCodes: ['44'],
    capital: 'St. Peter Port',
    altSpellings: ['GG', 'Bailiwick of Guernsey', 'Bailliage de Guernesey'],
    region: 'Europe'
  },
  {
    name: 'Guinea',
    translatedName: {
      en: 'Guinea',
      de: 'Guinea',
      nl: 'Guinea',
      es: 'Guinea',
      fr: 'Guinée',
      hu: 'Guinea',
      ca: 'Guinea',
    },
    topLevelDomain: ['.gn'],
    alpha2Code: 'GN',
    alpha3Code: 'GIN',
    callingCodes: ['224'],
    capital: 'Conakry',
    altSpellings: ['GN', 'Republic of Guinea', 'République de Guinée'],
    region: 'Africa'
  },
  {
    name: 'Guinea-Bissau',
    translatedName: {
      en: 'Guinea-Bissau',
      de: 'Guinea-Bissau',
      nl: 'Guinee-Bissau',
      es: 'Guinea-Bissau',
      fr: 'Guinée-Bissau',
      hu: 'Bissau-Guinea',
      ca: 'Guinea Bissau',
    },
    topLevelDomain: ['.gw'],
    alpha2Code: 'GW',
    alpha3Code: 'GNB',
    callingCodes: ['245'],
    capital: 'Bissau',
    altSpellings: ['GW', 'Republic of Guinea-Bissau', 'República da Guiné-Bissau'],
    region: 'Africa'
  },
  {
    name: 'Guyana',
    translatedName: {
      en: 'Guyana',
      de: 'Guyana',
      nl: 'Guyana',
      es: 'Guayana',
      fr: 'Guyane',
      hu: 'Guyana',
      ca: 'Guyana',
    },
    topLevelDomain: ['.gy'],
    alpha2Code: 'GY',
    alpha3Code: 'GUY',
    callingCodes: ['592'],
    capital: 'Georgetown',
    altSpellings: ['GY', 'Co-operative Republic of Guyana'],
    region: 'Americas'
  },
  {
    name: 'Haiti',
    translatedName: {
      en: 'Haiti',
      de: 'Haiti',
      nl: 'Haïti',
      es: 'Haití',
      fr: 'Haïti',
      hu: 'Haiti',
      ca: 'Haití',
    },
    topLevelDomain: ['.ht'],
    alpha2Code: 'HT',
    alpha3Code: 'HTI',
    callingCodes: ['509'],
    capital: 'Port-au-Prince',
    altSpellings: ['HT', 'Republic of Haiti', "République d'Haïti", 'Repiblik Ayiti'],
    region: 'Americas'
  },
  {
    name: 'Heard Island and McDonald Islands',
    translatedName: {
      en: 'Heard Island and McDonald Islands',
      de: 'Heard Insel und McDonald Inseln',
      nl: 'Heard eiland- en McDonald -eilanden',
      es: 'Islas Heard y Islas McDonald',
      fr: 'Îles Heard et îles McDonald',
      hu: 'Heard-sziget és McDonald-szigetek',
      ca: 'Heard Island i McDonald Islands',
    },
    topLevelDomain: ['.hm', '.aq'],
    alpha2Code: 'HM',
    alpha3Code: 'HMD',
    callingCodes: ['672'],
    capital: '',
    altSpellings: ['HM'],
    region: ''
  },
  {
    name: 'Holy See',
    translatedName: {
      en: 'Holy See',
      de: 'Heiliger Stuhl',
      nl: 'Vaticaan',
      es: 'Santa Sede',
      fr: 'Vatican',
      hu: 'Szentszék',
      ca: 'Santa Seu',
    },
    topLevelDomain: ['.va'],
    alpha2Code: 'VA',
    alpha3Code: 'VAT',
    callingCodes: ['379'],
    capital: 'Rome',
    altSpellings: ['Sancta Sedes', 'Vatican', 'The Vatican'],
    region: 'Europe'
  },
  {
    name: 'Honduras',
    translatedName: {
      en: 'Honduras',
      de: 'Honduras',
      nl: 'Honduras',
      es: 'Honduras',
      fr: 'Honduras',
      hu: 'Honduras',
      ca: 'Hondures',
    },
    topLevelDomain: ['.hn'],
    alpha2Code: 'HN',
    alpha3Code: 'HND',
    callingCodes: ['504'],
    capital: 'Tegucigalpa',
    altSpellings: ['HN', 'Republic of Honduras', 'República de Honduras'],
    region: 'Americas'
  },
  {
    name: 'Hong Kong',
    translatedName: {
      en: 'Hong Kong',
      de: 'Hongkong',
      nl: 'Hong Kong',
      es: 'Hong Kong',
      fr: 'Hong Kong',
      hu: 'Hong Kong',
      ca: 'Hong Kong',
    },
    topLevelDomain: ['.hk'],
    alpha2Code: 'HK',
    alpha3Code: 'HKG',
    callingCodes: ['852'],
    capital: 'City of Victoria',
    altSpellings: ['HK', '香港'],
    region: 'Asia'
  },
  {
    name: 'Hungary',
    translatedName: {
      en: 'Hungary',
      de: 'Ungarn',
      nl: 'Hongarije',
      es: 'Hungría',
      fr: 'Hongrie',
      hu: 'Magyarország',
      ca: 'Hongria',
    },
    topLevelDomain: ['.hu'],
    alpha2Code: 'HU',
    alpha3Code: 'HUN',
    callingCodes: ['36'],
    capital: 'Budapest',
    altSpellings: ['HU'],
    region: 'Europe'
  },
  {
    name: 'Iceland',
    translatedName: {
      en: 'Iceland',
      de: 'Island',
      nl: 'Ijsland',
      es: 'Islandia',
      fr: 'Islande',
      hu: 'Izland',
      ca: 'Islàndia',
    },
    topLevelDomain: ['.is'],
    alpha2Code: 'IS',
    alpha3Code: 'ISL',
    callingCodes: ['354'],
    capital: 'Reykjavík',
    altSpellings: ['IS', 'Island', 'Republic of Iceland', 'Lýðveldið Ísland'],
    region: 'Europe'
  },
  {
    name: 'India',
    translatedName: {
      en: 'India',
      de: 'Indien',
      nl: 'India',
      es: 'India',
      fr: 'Inde',
      hu: 'India',
      ca: 'Índia',
    },
    topLevelDomain: ['.in'],
    alpha2Code: 'IN',
    alpha3Code: 'IND',
    callingCodes: ['91'],
    capital: 'New Delhi',
    altSpellings: ['IN', 'Bhārat', 'Republic of India', 'Bharat Ganrajya'],
    region: 'Asia'
  },
  {
    name: 'Indonesia',
    translatedName: {
      en: 'Indonesia',
      de: 'Indonesien',
      nl: 'Indonesië',
      es: 'Indonesia',
      fr: 'Indonésie',
      hu: 'Indonézia',
      ca: 'Indonèsia',
    },
    topLevelDomain: ['.id'],
    alpha2Code: 'ID',
    alpha3Code: 'IDN',
    callingCodes: ['62'],
    capital: 'Jakarta',
    altSpellings: ['ID', 'Republic of Indonesia', 'Republik Indonesia'],
    region: 'Asia'
  },
  {
    name: "Côte d'Ivoire",
    translatedName: {
      en: "Côte d'Ivoire",
      de: "Côte d'Ivoire",
      nl: 'Ivoorkust',
      es: "Côte d'Ivoire",
      fr: "Côte d'Ivoire",
      hu: 'Elefántcsontpart',
      ca: 'Costa d\'Ivoire',
    },
    topLevelDomain: ['.ci'],
    alpha2Code: 'CI',
    alpha3Code: 'CIV',
    callingCodes: ['225'],
    capital: 'Yamoussoukro',
    altSpellings: ['CI', 'Ivory Coast', "Republic of Côte d'Ivoire", "République de Côte d'Ivoire"],
    region: 'Africa'
  },
  {
    name: 'Iran, Islamic Republic of',
    translatedName: {
      en: 'Iran, Islamic Republic',
      de: 'Iran, Islamische Republik',
      nl: 'Iran, Islamitische Republiek',
      es: 'Irán, República Islámica',
      fr: "Iran, République islamique",
      hu: 'Irán, Iszlám Köztársaság',
      ca: 'Iran, República Islàmica',
    },
    topLevelDomain: ['.ir'],
    alpha2Code: 'IR',
    alpha3Code: 'IRN',
    callingCodes: ['98'],
    capital: 'Tehran',
    altSpellings: ['IR', 'Islamic Republic of Iran', 'Jomhuri-ye Eslāmi-ye Irān'],
    region: 'Asia'
  },
  {
    name: 'Iraq',
    translatedName: {
      en: 'Iraq',
      de: 'Irak',
      nl: 'Irak',
      es: 'Irak',
      fr: 'Irak',
      hu: 'Irak',
      ca: 'Iraq',
    },
    topLevelDomain: ['.iq'],
    alpha2Code: 'IQ',
    alpha3Code: 'IRQ',
    callingCodes: ['964'],
    capital: 'Baghdad',
    altSpellings: ['IQ', 'Republic of Iraq', 'Jumhūriyyat al-‘Irāq'],
    region: 'Asia'
  },
  {
    name: 'Ireland',
    translatedName: {
      en: 'Ireland',
      de: 'Irland',
      nl: 'Ierland',
      es: 'Irlanda',
      fr: 'Irlande',
      hu: 'Írország',
      ca: 'Irlanda',
    },
    topLevelDomain: ['.ie'],
    alpha2Code: 'IE',
    alpha3Code: 'IRL',
    callingCodes: ['353'],
    capital: 'Dublin',
    altSpellings: ['IE', 'Éire', 'Republic of Ireland', 'Poblacht na hÉireann'],
    region: 'Europe'
  },
  {
    name: 'Isle of Man',
    translatedName: {
      en: 'Isle of Man',
      de: 'Isle of Man',
      nl: 'Isle of Man',
      es: 'Isla del Man',
      fr: 'île de Man',
      hu: 'Man-sziget',
      ca: 'Illa de Man',
    },
    topLevelDomain: ['.im'],
    alpha2Code: 'IM',
    alpha3Code: 'IMN',
    callingCodes: ['44'],
    capital: 'Douglas',
    altSpellings: ['IM', 'Ellan Vannin', 'Mann', 'Mannin'],
    region: 'Europe'
  },
  {
    name: 'Israel',
    translatedName: {
      en: 'Israel',
      de: 'Israel',
      nl: 'Israël',
      es: 'Israel',
      fr: 'Israël',
      hu: 'Izrael',
      ca: 'Israel',
    },
    topLevelDomain: ['.il'],
    alpha2Code: 'IL',
    alpha3Code: 'ISR',
    callingCodes: ['972'],
    capital: 'Jerusalem',
    altSpellings: ['IL', 'State of Israel', "Medīnat Yisrā'el"],
    region: 'Asia'
  },
  {
    name: 'Italy',
    translatedName: {
      en: 'Italy',
      de: 'Italien',
      nl: 'Italië',
      es: 'Italia',
      fr: 'Italie',
      hu: 'Olaszország',
      ca: 'Itàlia',
    },
    topLevelDomain: ['.it'],
    alpha2Code: 'IT',
    alpha3Code: 'ITA',
    callingCodes: ['39'],
    capital: 'Rome',
    altSpellings: ['IT', 'Italian Republic', 'Repubblica italiana'],
    region: 'Europe'
  },
  {
    name: 'Jamaica',
    translatedName: {
      en: 'Jamaica',
      de: 'Jamaika',
      nl: 'Jamaica',
      es: 'Jamaica',
      fr: 'Jamaïque',
      hu: 'Jamaica',
      ca: 'Jamaica',
    },
    topLevelDomain: ['.jm'],
    alpha2Code: 'JM',
    alpha3Code: 'JAM',
    callingCodes: ['1'],
    capital: 'Kingston',
    altSpellings: ['JM'],
    region: 'Americas'
  },
  {
    name: 'Japan',
    translatedName: {
      en: 'Japan',
      de: 'Japan',
      nl: 'Japan',
      es: 'Japón',
      fr: 'Japon',
      hu: 'Japán',
      ca: 'Japó',
    },
    topLevelDomain: ['.jp'],
    alpha2Code: 'JP',
    alpha3Code: 'JPN',
    callingCodes: ['81'],
    capital: 'Tokyo',
    altSpellings: ['JP', 'Nippon', 'Nihon'],
    region: 'Asia'
  },
  {
    name: 'Jersey',
    translatedName: {
      en: 'Jersey',
      de: 'Jersey',
      nl: 'Jersey',
      es: 'Jersey',
      fr: 'Jersey',
      hu: 'Jersey',
      ca: 'Jersei',
    },
    topLevelDomain: ['.je'],
    alpha2Code: 'JE',
    alpha3Code: 'JEY',
    callingCodes: ['44'],
    capital: 'Saint Helier',
    altSpellings: ['JE', 'Bailiwick of Jersey', 'Bailliage de Jersey', 'Bailliage dé Jèrri'],
    region: 'Europe'
  },
  {
    name: 'Jordan',
    translatedName: {
      en: 'Jordan',
      de: 'Jordanien',
      nl: 'Jordanië',
      es: 'Jordania',
      fr: 'Jordan',
      hu: 'Jordánia',
      ca: 'Jordània',
    },
    topLevelDomain: ['.jo'],
    alpha2Code: 'JO',
    alpha3Code: 'JOR',
    callingCodes: ['962'],
    capital: 'Amman',
    altSpellings: ['JO', 'Hashemite Kingdom of Jordan', 'al-Mamlakah al-Urdunīyah al-Hāshimīyah'],
    region: 'Asia'
  },
  {
    name: 'Kazakhstan',
    translatedName: {
      en: 'Kazakhstan',
      de: 'Kasachstan',
      nl: 'Kazachstan',
      es: 'Kazajstán',
      fr: 'Kazakhstan',
      hu: 'Kazahsztán',
      ca: 'Kazakhstan',
    },
    topLevelDomain: ['.kz', '.қаз'],
    alpha2Code: 'KZ',
    alpha3Code: 'KAZ',
    callingCodes: ['76', '77'],
    capital: 'Astana',
    altSpellings: [
      'KZ',
      'Qazaqstan',
      'Казахстан',
      'Republic of Kazakhstan',
      'Қазақстан Республикасы',
      'Qazaqstan Respublïkası',
      'Республика Казахстан',
      'Respublika Kazakhstan'
    ],
    region: 'Asia'
  },
  {
    name: 'Kenya',
    translatedName: {
      en: 'Kenya',
      de: 'Kenia',
      nl: 'Kenia',
      es: 'Kenia',
      fr: 'Kenya',
      hu: 'Kenya',
      ca: 'Kenya',
    },
    topLevelDomain: ['.ke'],
    alpha2Code: 'KE',
    alpha3Code: 'KEN',
    callingCodes: ['254'],
    capital: 'Nairobi',
    altSpellings: ['KE', 'Republic of Kenya', 'Jamhuri ya Kenya'],
    region: 'Africa'
  },
  {
    name: 'Kiribati',
    translatedName: {
      en: 'Kiribati',
      de: 'Kiribati',
      nl: 'Kiribati',
      es: 'Kiribati',
      fr: 'Kiribati',
      hu: 'Kiribati',
      ca: 'Kiribati',
    },
    topLevelDomain: ['.ki'],
    alpha2Code: 'KI',
    alpha3Code: 'KIR',
    callingCodes: ['686'],
    capital: 'South Tarawa',
    altSpellings: ['KI', 'Republic of Kiribati', 'Ribaberiki Kiribati'],
    region: 'Oceania'
  },
  {
    name: 'Kuwait',
    translatedName: {
      en: 'Kuwait',
      de: 'Kuwait',
      nl: 'Koeweit',
      es: 'Kuwait',
      fr: 'Koweit',
      hu: 'Kuvait',
      ca: 'Kuwait',
    },
    topLevelDomain: ['.kw'],
    alpha2Code: 'KW',
    alpha3Code: 'KWT',
    callingCodes: ['965'],
    capital: 'Kuwait City',
    altSpellings: ['KW', 'State of Kuwait', 'Dawlat al-Kuwait'],
    region: 'Asia'
  },
  {
    name: 'Kyrgyzstan',
    translatedName: {
      en: 'Kyrgyzstan',
      de: 'Kirgisistan',
      nl: 'Kirgizisch',
      es: 'Kirguistán',
      fr: 'Kirghizistan',
      hu: 'Kirgizisztán',
      ca: 'Kirguizistan',
    },
    topLevelDomain: ['.kg'],
    alpha2Code: 'KG',
    alpha3Code: 'KGZ',
    callingCodes: ['996'],
    capital: 'Bishkek',
    altSpellings: [
      'KG',
      'Киргизия',
      'Kyrgyz Republic',
      'Кыргыз Республикасы',
      'Kyrgyz Respublikasy'
    ],
    region: 'Asia'
  },
  {
    name: "Lao People's Democratic Republic",
    translatedName: {
      en: "Lao People's Democratic Republic",
      de: 'Demokratische Volksrepublik Laos',
      nl: 'Lao Democratische Volksrepubliek',
      es: 'República Democrática Popular Lao',
      fr: 'République démocratique populaire lao',
      hu: 'Laoszi Népi Demokratikus Köztársaság',
      ca: 'República Democràtica Popular Lao',
    },
    topLevelDomain: ['.la'],
    alpha2Code: 'LA',
    alpha3Code: 'LAO',
    callingCodes: ['856'],
    capital: 'Vientiane',
    altSpellings: [
      'LA',
      'Lao',
      'Laos',
      "Lao People's Democratic Republic",
      'Sathalanalat Paxathipatai Paxaxon Lao'
    ],
    region: 'Asia'
  },
  {
    name: 'Latvia',
    translatedName: {
      en: 'Latvia',
      de: 'Lettland',
      nl: 'Letland',
      es: 'Letonia',
      fr: 'Lettonie',
      hu: 'Lettország',
      ca: 'Letònia',
    },
    topLevelDomain: ['.lv'],
    alpha2Code: 'LV',
    alpha3Code: 'LVA',
    callingCodes: ['371'],
    capital: 'Riga',
    altSpellings: ['LV', 'Republic of Latvia', 'Latvijas Republika'],
    region: 'Europe'
  },
  {
    name: 'Lebanon',
    translatedName: {
      en: 'Lebanon',
      de: 'Libanon',
      nl: 'Libanon',
      es: 'Líbano',
      fr: 'Liban',
      hu: 'Libanon',
      ca: 'Líban',
    },
    topLevelDomain: ['.lb'],
    alpha2Code: 'LB',
    alpha3Code: 'LBN',
    callingCodes: ['961'],
    capital: 'Beirut',
    altSpellings: ['LB', 'Lebanese Republic', 'Al-Jumhūrīyah Al-Libnānīyah'],
    region: 'Asia'
  },
  {
    name: 'Lesotho',
    translatedName: {
      en: 'Lesotho',
      de: 'Lesotho',
      nl: 'Lesotho',
      es: 'Lesoto',
      fr: 'Lesotho',
      hu: 'Lesotho',
      ca: 'Lesotho',
    },
    topLevelDomain: ['.ls'],
    alpha2Code: 'LS',
    alpha3Code: 'LSO',
    callingCodes: ['266'],
    capital: 'Maseru',
    altSpellings: ['LS', 'Kingdom of Lesotho', 'Muso oa Lesotho'],
    region: 'Africa'
  },
  {
    name: 'Liberia',
    translatedName: {
      en: 'Liberia',
      de: 'Liberia',
      nl: 'Liberia',
      es: 'Liberia',
      fr: 'Libéria',
      hu: 'Libéria',
      ca: 'Libèria',
    },
    topLevelDomain: ['.lr'],
    alpha2Code: 'LR',
    alpha3Code: 'LBR',
    callingCodes: ['231'],
    capital: 'Monrovia',
    altSpellings: ['LR', 'Republic of Liberia'],
    region: 'Africa'
  },
  {
    name: 'Libya',
    translatedName: {
      en: 'Libya',
      de: 'Libyen',
      nl: 'Libië',
      es: 'Libia',
      fr: 'Libye',
      hu: 'Líbia',
      ca: 'Líban',
    },
    topLevelDomain: ['.ly'],
    alpha2Code: 'LY',
    alpha3Code: 'LBY',
    callingCodes: ['218'],
    capital: 'Tripoli',
    altSpellings: ['LY', 'State of Libya', 'Dawlat Libya'],
    region: 'Africa'
  },
  {
    name: 'Liechtenstein',
    translatedName: {
      en: 'Liechtenstein',
      de: 'Liechtenstein',
      nl: 'Liechtenstein',
      es: 'Liechtenstein',
      fr: 'Liechtenstein',
      hu: 'Liechtenstein',
      ca: 'Liechtenstein',
    },
    topLevelDomain: ['.li'],
    alpha2Code: 'LI',
    alpha3Code: 'LIE',
    callingCodes: ['423'],
    capital: 'Vaduz',
    altSpellings: ['LI', 'Principality of Liechtenstein', 'Fürstentum Liechtenstein'],
    region: 'Europe'
  },
  {
    name: 'Lithuania',
    translatedName: {
      en: 'Lithuania',
      de: 'Litauen',
      nl: 'Litouwen',
      es: 'Lituania',
      fr: 'Lituanie',
      hu: 'Litvánia',
      ca: 'Lituània',
    },
    topLevelDomain: ['.lt'],
    alpha2Code: 'LT',
    alpha3Code: 'LTU',
    callingCodes: ['370'],
    capital: 'Vilnius',
    altSpellings: ['LT', 'Republic of Lithuania', 'Lietuvos Respublika'],
    region: 'Europe'
  },
  {
    name: 'Luxembourg',
    translatedName: {
      en: 'Luxembourg',
      de: 'Luxemburg',
      nl: 'Luxemburg',
      es: 'Luxemburgo',
      fr: 'Luxembourg',
      hu: 'Luxemburg',
      ca: 'Luxemburg',
    },
    topLevelDomain: ['.lu'],
    alpha2Code: 'LU',
    alpha3Code: 'LUX',
    callingCodes: ['352'],
    capital: 'Luxembourg',
    altSpellings: [
      'LU',
      'Grand Duchy of Luxembourg',
      'Grand-Duché de Luxembourg',
      'Großherzogtum Luxemburg',
      'Groussherzogtum Lëtzebuerg'
    ],
    region: 'Europe'
  },
  {
    name: 'Macao',
    translatedName: {
      en: 'Macao',
      de: 'Macao',
      nl: 'Macao',
      es: 'Macao',
      fr: 'Macao',
      hu: 'Makaó',
      ca: 'Macau',
    },
    topLevelDomain: ['.mo'],
    alpha2Code: 'MO',
    alpha3Code: 'MAC',
    callingCodes: ['853'],
    capital: '',
    altSpellings: [
      'MO',
      '澳门',
      "Macao Special Administrative Region of the People's Republic of China",
      '中華人民共和國澳門特別行政區',
      'Região Administrativa Especial de Macau da República Popular da China'
    ],
    region: 'Asia'
  },
  {
    name: 'North Macedonia',
    translatedName: {
      en: 'North Macedonia',
      de: 'Nord-Mazedonien',
      nl: 'Noord -Macedonië',
      es: 'Macedonia del Norte',
      fr: "Macédoine du Nord",
      hu: 'Észak-Macedónia',
      ca: 'Macedònia del Nord',
    },
    topLevelDomain: ['.mk'],
    alpha2Code: 'MK',
    alpha3Code: 'MKD',
    callingCodes: ['389'],
    capital: 'Skopje',
    altSpellings: ['MK', 'Republic of Macedonia', 'Република Македонија'],
    region: 'Europe'
  },
  {
    name: 'Madagascar',
    translatedName: {
      en: 'Madagascar',
      de: 'Madagaskar',
      nl: 'Madagascar',
      es: 'Madagascar',
      fr: 'Madagascar',
      hu: 'Madagaszkár',
      ca: 'Madagascar',
    },
    topLevelDomain: ['.mg'],
    alpha2Code: 'MG',
    alpha3Code: 'MDG',
    callingCodes: ['261'],
    capital: 'Antananarivo',
    altSpellings: [
      'MG',
      'Republic of Madagascar',
      "Repoblikan'i Madagasikara",
      'République de Madagascar'
    ],
    region: 'Africa'
  },
  {
    name: 'Malawi',
    translatedName: {
      en: 'Malawi',
      de: 'Malawi',
      nl: 'Malawi',
      es: 'Malawi',
      fr: 'Malawi',
      hu: 'Malawi',
      ca: 'Malawi',
    },
    topLevelDomain: ['.mw'],
    alpha2Code: 'MW',
    alpha3Code: 'MWI',
    callingCodes: ['265'],
    capital: 'Lilongwe',
    altSpellings: ['MW', 'Republic of Malawi'],
    region: 'Africa'
  },
  {
    name: 'Malaysia',
    translatedName: {
      en: 'Malaysia',
      de: 'Malaysia',
      nl: 'Maleisië',
      es: 'Malasia',
      fr: 'Malaisie',
      hu: 'Malajzia',
      ca: 'Malàisia',
    },
    topLevelDomain: ['.my'],
    alpha2Code: 'MY',
    alpha3Code: 'MYS',
    callingCodes: ['60'],
    capital: 'Kuala Lumpur',
    altSpellings: ['MY'],
    region: 'Asia'
  },
  {
    name: 'Maldives',
    translatedName: {
      en: 'Maldives',
      de: 'Malediven',
      nl: 'Maldiven',
      es: 'Maldivas',
      fr: 'Maldives',
      hu: 'Maldív-szigetek',
      ca: 'Maldives',
    },
    topLevelDomain: ['.mv'],
    alpha2Code: 'MV',
    alpha3Code: 'MDV',
    callingCodes: ['960'],
    capital: 'Malé',
    altSpellings: [
      'MV',
      'Maldive Islands',
      'Republic of the Maldives',
      'Dhivehi Raajjeyge Jumhooriyya'
    ],
    region: 'Asia'
  },
  {
    name: 'Mali',
    translatedName: {
      en: 'Mali',
      de: 'Mali',
      nl: 'Mali',
      es: 'Malí',
      fr: 'Mali',
      hu: 'Mali',
      ca: 'Mali',
    },
    topLevelDomain: ['.ml'],
    alpha2Code: 'ML',
    alpha3Code: 'MLI',
    callingCodes: ['223'],
    capital: 'Bamako',
    altSpellings: ['ML', 'Republic of Mali', 'République du Mali'],
    region: 'Africa'
  },
  {
    name: 'Malta',
    translatedName: {
      en: 'Malta',
      de: 'Malta',
      nl: 'Malta',
      es: 'Malta',
      fr: 'Malte',
      hu: 'Málta',
      ca: 'Malta',
    },
    topLevelDomain: ['.mt'],
    alpha2Code: 'MT',
    alpha3Code: 'MLT',
    callingCodes: ['356'],
    capital: 'Valletta',
    altSpellings: ['MT', 'Republic of Malta', "Repubblika ta' Malta"],
    region: 'Europe'
  },
  {
    name: 'Marshall Islands',
    translatedName: {
      en: 'Marshall Islands',
      de: 'Marshallinseln',
      nl: 'Marshall eilanden',
      es: 'Islas Marshall',
      fr: 'Iles Marshall',
      hu: 'Marshall-szigetek',
      ca: 'Illes Marshall',
    },
    topLevelDomain: ['.mh'],
    alpha2Code: 'MH',
    alpha3Code: 'MHL',
    callingCodes: ['692'],
    capital: 'Majuro',
    altSpellings: ['MH', 'Republic of the Marshall Islands', 'Aolepān Aorōkin M̧ajeļ'],
    region: 'Oceania'
  },
  {
    name: 'Martinique',
    translatedName: {
      en: 'Martinique',
      de: 'Martinique',
      nl: 'Martinique',
      es: 'Martinica',
      fr: 'Martinique',
      hu: 'Martinique',
      ca: 'Martinica',
    },
    topLevelDomain: ['.mq'],
    alpha2Code: 'MQ',
    alpha3Code: 'MTQ',
    callingCodes: ['596'],
    capital: 'Fort-de-France',
    altSpellings: ['MQ'],
    region: 'Americas'
  },
  {
    name: 'Mauritania',
    translatedName: {
      en: 'Mauritania',
      de: 'Mauretanien',
      nl: 'Mauritanië',
      es: 'Mauritania',
      fr: 'Mauritanie',
      hu: 'Mauritánia',
      ca: 'Mauritània',
    },
    topLevelDomain: ['.mr'],
    alpha2Code: 'MR',
    alpha3Code: 'MRT',
    callingCodes: ['222'],
    capital: 'Nouakchott',
    altSpellings: [
      'MR',
      'Islamic Republic of Mauritania',
      'al-Jumhūriyyah al-ʾIslāmiyyah al-Mūrītāniyyah'
    ],
    region: 'Africa'
  },
  {
    name: 'Mauritius',
    translatedName: {
      en: 'Mauritius',
      de: 'Mauritius',
      nl: 'Mauritius',
      es: 'Mauricio',
      fr: 'Maurice',
      hu: 'Mauritius',
      ca: 'Maurici',
    },
    topLevelDomain: ['.mu'],
    alpha2Code: 'MU',
    alpha3Code: 'MUS',
    callingCodes: ['230'],
    capital: 'Port Louis',
    altSpellings: ['MU', 'Republic of Mauritius', 'République de Maurice'],
    region: 'Africa'
  },
  {
    name: 'Mayotte',
    translatedName: {
      en: 'Mayotte',
      de: 'Mayotte',
      nl: 'Mayotte',
      es: 'Mayotte',
      fr: 'Mayotte',
      hu: 'Mayotte',
      ca: 'Mayotte',
    },
    topLevelDomain: ['.yt'],
    alpha2Code: 'YT',
    alpha3Code: 'MYT',
    callingCodes: ['262'],
    capital: 'Mamoudzou',
    altSpellings: ['YT', 'Department of Mayotte', 'Département de Mayotte'],
    region: 'Africa'
  },
  {
    name: 'Mexico',
    translatedName: {
      en: 'Mexico',
      de: 'Mexiko',
      nl: 'Mexico',
      es: 'México',
      fr: 'Mexique',
      hu: 'Mexikó',
      ca: 'Mèxic',
    },
    topLevelDomain: ['.mx'],
    alpha2Code: 'MX',
    alpha3Code: 'MEX',
    callingCodes: ['52'],
    capital: 'Mexico City',
    altSpellings: ['MX', 'Mexicanos', 'United Mexican States', 'Estados Unidos Mexicanos'],
    region: 'Americas'
  },
  {
    name: 'Micronesia, Federated States of',
    translatedName: {
      en: 'Micronesia, Federated States of',
      de: 'Mikronesien, Föderierte Staaten von',
      nl: 'Micronesië, Federated States of',
      es: 'Micronesia, Estados Federados de',
      fr: 'Micronésie, États Fédérés de',
      hu: 'Mikronézia, Szövetségi Államok',
      ca: 'Micronèsia, Estats Federats de',
    },
    topLevelDomain: ['.fm'],
    alpha2Code: 'FM',
    alpha3Code: 'FSM',
    callingCodes: ['691'],
    capital: 'Palikir',
    altSpellings: ['FM', 'Federated States of Micronesia'],
    region: 'Oceania'
  },
  {
    name: 'Moldova',
    translatedName: {
      en: 'Moldova',
      de: 'Moldawien',
      nl: 'Moldavië',
      es: 'Moldavia',
      fr: 'Moldavie',
      hu: 'Moldova',
      ca: 'Moldàvia',
    },
    topLevelDomain: ['.md'],
    alpha2Code: 'MD',
    alpha3Code: 'MDA',
    callingCodes: ['373'],
    capital: 'Chișinău',
    altSpellings: ['MD', 'Republic of Moldova', 'Republica Moldova'],
    region: 'Europe'
  },
  {
    name: 'Monaco',
    translatedName: {
      en: 'Monaco',
      de: 'Monaco',
      nl: 'Monaco',
      es: 'Mónaco',
      fr: 'Monaco',
      hu: 'Monaco',
      ca: 'Mònaco',
    },
    topLevelDomain: ['.mc'],
    alpha2Code: 'MC',
    alpha3Code: 'MCO',
    callingCodes: ['377'],
    capital: 'Monaco',
    altSpellings: ['MC', 'Principality of Monaco', 'Principauté de Monaco'],
    region: 'Europe'
  },
  {
    name: 'Mongolia',
    translatedName: {
      en: 'Mongolia',
      de: 'Mongolei',
      nl: 'Mongolië',
      es: 'Mongolia',
      fr: 'Mongolie',
      hu: 'Mongólia',
      ca: 'Mongòlia',
    },
    topLevelDomain: ['.mn'],
    alpha2Code: 'MN',
    alpha3Code: 'MNG',
    callingCodes: ['976'],
    capital: 'Ulan Bator',
    altSpellings: ['MN'],
    region: 'Asia'
  },
  {
    name: 'Montenegro',
    translatedName: {
      en: 'Montenegro',
      de: 'Montenegro',
      nl: 'Montenegro',
      es: 'Montenegro',
      fr: 'Monténégro',
      hu: 'Montenegró',
      ca: 'Montenegro',
    },
    topLevelDomain: ['.me'],
    alpha2Code: 'ME',
    alpha3Code: 'MNE',
    callingCodes: ['382'],
    capital: 'Podgorica',
    altSpellings: ['ME', 'Crna Gora'],
    region: 'Europe'
  },
  {
    name: 'Montserrat',
    translatedName: {
      en: 'Montserrat',
      de: 'Montserrat',
      nl: 'Montserrat',
      es: 'Montserrat',
      fr: 'Montserrat',
      hu: 'Montserrat',
      ca: 'Montserrat',
    },
    topLevelDomain: ['.ms'],
    alpha2Code: 'MS',
    alpha3Code: 'MSR',
    callingCodes: ['1'],
    capital: 'Plymouth',
    altSpellings: ['MS'],
    region: 'Americas'
  },
  {
    name: 'Morocco',
    translatedName: {
      en: 'Morocco',
      de: 'Marokko',
      nl: 'Marokko',
      es: 'Marruecos',
      fr: 'Maroc',
      hu: 'Marokkó',
      ca: 'Marroc',
    },
    topLevelDomain: ['.ma'],
    alpha2Code: 'MA',
    alpha3Code: 'MAR',
    callingCodes: ['212'],
    capital: 'Rabat',
    altSpellings: ['MA', 'Kingdom of Morocco', 'Al-Mamlakah al-Maġribiyah'],
    region: 'Africa'
  },
  {
    name: 'Mozambique',
    translatedName: {
      en: 'Mozambique',
      de: 'Mosambik',
      nl: 'Mozambique',
      es: 'Mozambique',
      fr: 'Mozambique',
      hu: 'Mozambik',
      ca: 'Moçambic',
    },
    topLevelDomain: ['.mz'],
    alpha2Code: 'MZ',
    alpha3Code: 'MOZ',
    callingCodes: ['258'],
    capital: 'Maputo',
    altSpellings: ['MZ', 'Republic of Mozambique', 'República de Moçambique'],
    region: 'Africa'
  },
  {
    name: 'Myanmar',
    translatedName: {
      en: 'Myanmar',
      de: 'Myanmar',
      nl: 'Myanmar',
      es: 'Myanmar',
      fr: 'Myanmar',
      hu: 'Mianmar',
      ca: 'Myanmar',
    },
    topLevelDomain: ['.mm'],
    alpha2Code: 'MM',
    alpha3Code: 'MMR',
    callingCodes: ['95'],
    capital: 'Naypyidaw',
    altSpellings: [
      'MM',
      'Burma',
      'Republic of the Union of Myanmar',
      'Pyidaunzu Thanmăda Myăma Nainngandaw'
    ],
    region: 'Asia'
  },
  {
    name: 'Namibia',
    translatedName: {
      en: 'Namibia',
      de: 'Namibia',
      nl: 'Namibië',
      es: 'Namibia',
      fr: 'Namibie',
      hu: 'Namíbia',
      ca: 'Namíbia',
    },
    topLevelDomain: ['.na'],
    alpha2Code: 'NA',
    alpha3Code: 'NAM',
    callingCodes: ['264'],
    capital: 'Windhoek',
    altSpellings: ['NA', 'Namibië', 'Republic of Namibia'],
    region: 'Africa'
  },
  {
    name: 'Nauru',
    translatedName: {
      en: 'Nauru',
      de: 'Nauru',
      nl: 'Nauru',
      es: 'Nauru',
      fr: 'Nauru',
      hu: 'Nauru',
      ca: 'Nauru',
    },
    topLevelDomain: ['.nr'],
    alpha2Code: 'NR',
    alpha3Code: 'NRU',
    callingCodes: ['674'],
    capital: 'Yaren',
    altSpellings: ['NR', 'Naoero', 'Pleasant Island', 'Republic of Nauru', 'Ripublik Naoero'],
    region: 'Oceania'
  },
  {
    name: 'Nepal',
    translatedName: {
      en: 'Nepal',
      de: 'Nepal',
      nl: 'Nepal',
      es: 'Nepal',
      fr: 'Népal',
      hu: 'Nepál',
      ca: 'Nepal',
    },
    topLevelDomain: ['.np'],
    alpha2Code: 'NP',
    alpha3Code: 'NPL',
    callingCodes: ['977'],
    capital: 'Kathmandu',
    altSpellings: ['NP', 'Federal Democratic Republic of Nepal', 'Loktāntrik Ganatantra Nepāl'],
    region: 'Asia'
  },
  {
    name: 'Netherlands',
    translatedName: {
      en: 'Netherlands',
      de: 'Niederlande',
      nl: 'Nederland',
      es: 'Países Bajos',
      fr: 'Pays-Bas',
      hu: 'Hollandia',
      ca: 'Països Baixos',
    },
    topLevelDomain: ['.nl'],
    alpha2Code: 'NL',
    alpha3Code: 'NLD',
    callingCodes: ['31'],
    capital: 'Amsterdam',
    altSpellings: ['NL', 'Holland', 'Nederland'],
    region: 'Europe'
  },
  {
    name: 'New Caledonia',
    translatedName: {
      en: 'New Caledonia',
      de: 'Neu-Kaledonien',
      nl: 'Nieuw-Caledonië',
      es: 'Nueva Caledonia',
      fr: 'Nouvelle Calédonie',
      hu: 'Új-Kaledónia',
      ca: 'Nova Caledònia',
    },
    topLevelDomain: ['.nc'],
    alpha2Code: 'NC',
    alpha3Code: 'NCL',
    callingCodes: ['687'],
    capital: 'Nouméa',
    altSpellings: ['NC'],
    region: 'Oceania'
  },
  {
    name: 'New Zealand',
    translatedName: {
      en: 'New Zealand',
      de: 'Neuseeland',
      nl: 'Nieuw-Zeeland',
      es: 'Nueva Zelanda',
      fr: 'Nouvelle-Zélande',
      hu: 'Új-Zéland',
      ca: 'Nova Zelanda',
    },
    topLevelDomain: ['.nz'],
    alpha2Code: 'NZ',
    alpha3Code: 'NZL',
    callingCodes: ['64'],
    capital: 'Wellington',
    altSpellings: ['NZ', 'Aotearoa'],
    region: 'Oceania'
  },
  {
    name: 'Nicaragua',
    translatedName: {
      en: 'Nicaragua',
      de: 'Nicaragua',
      nl: 'Nicaragua',
      es: 'Nicaragua',
      fr: 'Nicaragua',
      hu: 'Nicaragua',
      ca: 'Nicaragua',
    },
    topLevelDomain: ['.ni'],
    alpha2Code: 'NI',
    alpha3Code: 'NIC',
    callingCodes: ['505'],
    capital: 'Managua',
    altSpellings: ['NI', 'Republic of Nicaragua', 'República de Nicaragua'],
    region: 'Americas'
  },
  {
    name: 'Niger',
    translatedName: {
      en: 'Niger',
      de: 'Niger',
      nl: 'Niger',
      es: 'Níger',
      fr: 'Niger',
      hu: 'Niger',
      ca: 'Níger',
    },
    topLevelDomain: ['.ne'],
    alpha2Code: 'NE',
    alpha3Code: 'NER',
    callingCodes: ['227'],
    capital: 'Niamey',
    altSpellings: ['NE', 'Nijar', 'Republic of Niger', 'République du Niger'],
    region: 'Africa'
  },
  {
    name: 'Nigeria',
    translatedName: {
      en: 'Nigeria',
      de: 'Nigeria',
      nl: 'Nigeria',
      es: 'Nigeria',
      fr: 'Nigeria',
      hu: 'Nigéria',
      ca: 'Nigèria',
    },
    topLevelDomain: ['.ng'],
    alpha2Code: 'NG',
    alpha3Code: 'NGA',
    callingCodes: ['234'],
    capital: 'Abuja',
    altSpellings: ['NG', 'Nijeriya', 'Naíjíríà', 'Federal Republic of Nigeria'],
    region: 'Africa'
  },
  {
    name: 'Niue',
    translatedName: {
      en: 'Niue',
      de: 'Niue',
      nl: 'Niue',
      es: 'Niue',
      fr: 'Niue',
      hu: 'Niue',
      ca: 'Niue',
    },
    topLevelDomain: ['.nu'],
    alpha2Code: 'NU',
    alpha3Code: 'NIU',
    callingCodes: ['683'],
    capital: 'Alofi',
    altSpellings: ['NU'],
    region: 'Oceania'
  },
  {
    name: 'Norfolk Island',
    translatedName: {
      en: 'Norfolk Island',
      de: 'Norfolkinsel',
      nl: 'Norfolk Island',
      es: 'Isla Norfolk',
      fr: "l'ile de Norfolk",
      hu: 'Norfolk-sziget',
      ca: 'Illa Norfolk',
    },
    topLevelDomain: ['.nf'],
    alpha2Code: 'NF',
    alpha3Code: 'NFK',
    callingCodes: ['672'],
    capital: 'Kingston',
    altSpellings: ['NF', 'Territory of Norfolk Island', "Teratri of Norf'k Ailen"],
    region: 'Oceania'
  },
  {
    name: "Korea, Democratic People's Republic",
    translatedName: {
      en: "Korea, Democratic People's Republic",
      de: 'Korea, Demokratische Volksrepublik',
      nl: 'Korea, Democratische Volksrepubliek',
      es: 'Corea, República Popular Democrática',
      fr: 'République populaire démocratique de Corée',
      hu: 'Koreai Népi Demokratikus Népköztársaság',
      ca: 'Corea, República Democràtica Popular',
    },
    topLevelDomain: ['.kp'],
    alpha2Code: 'KP',
    alpha3Code: 'PRK',
    callingCodes: ['850'],
    capital: 'Pyongyang',
    altSpellings: [
      'KP',
      "Democratic People's Republic of Korea",
      '조선민주주의인민공화국',
      'Chosŏn Minjujuŭi Inmin Konghwaguk'
    ],
    region: 'Asia'
  },
  {
    name: 'Northern Mariana Islands',
    translatedName: {
      en: 'Northern Mariana Islands',
      de: 'Nördliche Marianneninseln',
      nl: 'Noordelijke Mariana eilanden',
      es: 'Islas Marianas del Norte',
      fr: 'Îles Mariannes du Nord',
      hu: 'Észak Mariana szigetek',
      ca: 'Illes del Nord de Mariana',
    },
    topLevelDomain: ['.mp'],
    alpha2Code: 'MP',
    alpha3Code: 'MNP',
    callingCodes: ['1'],
    capital: 'Saipan',
    altSpellings: [
      'MP',
      'Commonwealth of the Northern Mariana Islands',
      'Sankattan Siha Na Islas Mariånas'
    ],
    region: 'Oceania'
  },
  {
    name: 'Norway',
    translatedName: {
      en: 'Norway',
      de: 'Norwegen',
      nl: 'Noorwegen',
      es: 'Noruega',
      fr: 'Norvège',
      hu: 'Norvégia',
      ca: 'Noruega',
    },
    topLevelDomain: ['.no'],
    alpha2Code: 'NO',
    alpha3Code: 'NOR',
    callingCodes: ['47'],
    capital: 'Oslo',
    altSpellings: [
      'NO',
      'Norge',
      'Noreg',
      'Kingdom of Norway',
      'Kongeriket Norge',
      'Kongeriket Noreg'
    ],
    region: 'Europe'
  },
  {
    name: 'Oman',
    translatedName: {
      en: 'Oman',
      de: 'Oman',
      nl: 'Oman',
      es: 'Omán',
      fr: 'Oman',
      hu: 'Omán',
      ca: 'Oman',
    },
    topLevelDomain: ['.om'],
    alpha2Code: 'OM',
    alpha3Code: 'OMN',
    callingCodes: ['968'],
    capital: 'Muscat',
    altSpellings: ['OM', 'Sultanate of Oman', 'Salṭanat ʻUmān'],
    region: 'Asia'
  },
  {
    name: 'Pakistan',
    translatedName: {
      en: 'Pakistan',
      de: 'Pakistan',
      nl: 'Pakistan',
      es: 'Pakistán',
      fr: 'Pakistan',
      hu: 'Pakisztán',
      ca: 'Pakistan',
    },
    topLevelDomain: ['.pk'],
    alpha2Code: 'PK',
    alpha3Code: 'PAK',
    callingCodes: ['92'],
    capital: 'Islamabad',
    altSpellings: [
      'PK',
      'Pākistān',
      'Islamic Republic of Pakistan',
      "Islāmī Jumhūriya'eh Pākistān"
    ],
    region: 'Asia'
  },
  {
    name: 'Palau',
    translatedName: {
      en: 'Palau',
      de: 'Palau',
      nl: 'Palau',
      es: 'Palau',
      fr: 'Palaos',
      hu: 'Palau',
      ca: 'Palau',
    },
    topLevelDomain: ['.pw'],
    alpha2Code: 'PW',
    alpha3Code: 'PLW',
    callingCodes: ['680'],
    capital: 'Ngerulmud',
    altSpellings: ['PW', 'Republic of Palau', 'Beluu er a Belau'],
    region: 'Oceania'
  },
  {
    name: 'Palestine, State of',
    translatedName: {
      en: 'Palestine, State of',
      de: 'Palästina, Staat von',
      nl: 'Palestina, Staat van',
      es: 'Palestina, Estado de',
      fr: 'Palestine, État de',
      hu: 'Palesztina Állam',
      ca: 'Palestina, Estat de',
    },
    topLevelDomain: ['.ps'],
    alpha2Code: 'PS',
    alpha3Code: 'PSE',
    callingCodes: ['970'],
    capital: 'Ramallah',
    altSpellings: ['PS', 'State of Palestine', 'Dawlat Filasṭin'],
    region: 'Asia'
  },
  {
    name: 'Panama',
    translatedName: {
      en: 'Panama',
      de: 'Panama',
      nl: 'Panama',
      es: 'Panamá',
      fr: 'Panama',
      hu: 'Panama',
      ca: 'Panamà',
    },
    topLevelDomain: ['.pa'],
    alpha2Code: 'PA',
    alpha3Code: 'PAN',
    callingCodes: ['507'],
    capital: 'Panama City',
    altSpellings: ['PA', 'Republic of Panama', 'República de Panamá'],
    region: 'Americas'
  },
  {
    name: 'Papua New Guinea',
    translatedName: {
      en: 'Papua New Guinea',
      de: 'Papua Neuguinea',
      nl: 'Papoea-Nieuw-Guinea',
      es: 'Papúa Nueva Guinea',
      fr: 'Papouasie Nouvelle Guinée',
      hu: 'Pápua Új-Guinea',
      ca: 'Papua Nova Guinea',
    },
    topLevelDomain: ['.pg'],
    alpha2Code: 'PG',
    alpha3Code: 'PNG',
    callingCodes: ['675'],
    capital: 'Port Moresby',
    altSpellings: [
      'PG',
      'Independent State of Papua New Guinea',
      'Independen Stet bilong Papua Niugini'
    ],
    region: 'Oceania'
  },
  {
    name: 'Paraguay',
    translatedName: {
      en: 'Paraguay',
      de: 'Paraguay',
      nl: 'Paraguay',
      es: 'Paraguay',
      fr: 'Paraguay',
      hu: 'Paraguay',
      ca: 'Paraguai',
    },
    topLevelDomain: ['.py'],
    alpha2Code: 'PY',
    alpha3Code: 'PRY',
    callingCodes: ['595'],
    capital: 'Asunción',
    altSpellings: ['PY', 'Republic of Paraguay', 'República del Paraguay', 'Tetã Paraguái'],
    region: 'Americas'
  },
  {
    name: 'Peru',
    translatedName: {
      en: 'Peru',
      de: 'Peru',
      nl: 'Peru',
      es: 'Perú',
      fr: 'Pérou',
      hu: 'Peru',
      ca: 'Perú',
    },
    topLevelDomain: ['.pe'],
    alpha2Code: 'PE',
    alpha3Code: 'PER',
    callingCodes: ['51'],
    capital: 'Lima',
    altSpellings: ['PE', 'Republic of Peru', ' República del Perú'],
    region: 'Americas'
  },
  {
    name: 'Philippines',
    translatedName: {
      en: 'Philippines',
      de: 'Philippinen',
      nl: 'Filippijnen',
      es: 'Filipinas',
      fr: 'Philippines',
      hu: 'Fülöp-szigetek',
      ca: 'Filipines',
    },
    topLevelDomain: ['.ph'],
    alpha2Code: 'PH',
    alpha3Code: 'PHL',
    callingCodes: ['63'],
    capital: 'Manila',
    altSpellings: ['PH', 'Republic of the Philippines', 'Repúblika ng Pilipinas'],
    region: 'Asia'
  },
  {
    name: 'Pitcairn',
    translatedName: {
      en: 'Pitcairn',
      de: 'Pitcairn',
      nl: 'Pitcairn',
      es: 'Pitcairn',
      fr: 'Pitcairn',
      hu: 'Pitcairn',
      ca: 'Pitcairn',
    },
    topLevelDomain: ['.pn'],
    alpha2Code: 'PN',
    alpha3Code: 'PCN',
    callingCodes: ['64'],
    capital: 'Adamstown',
    altSpellings: ['PN', 'Pitcairn Henderson Ducie and Oeno Islands'],
    region: 'Oceania'
  },
  {
    name: 'Poland',
    translatedName: {
      en: 'Poland',
      de: 'Polen',
      nl: 'Polen',
      es: 'Polonia',
      fr: 'Pologne',
      hu: 'Lengyelország',
      ca: 'Polònia',
    },
    topLevelDomain: ['.pl'],
    alpha2Code: 'PL',
    alpha3Code: 'POL',
    callingCodes: ['48'],
    capital: 'Warsaw',
    altSpellings: ['PL', 'Republic of Poland', 'Rzeczpospolita Polska'],
    region: 'Europe'
  },
  {
    name: 'Portugal',
    translatedName: {
      en: 'Portugal',
      de: 'Portugal',
      nl: 'Portugal',
      es: 'Portugal',
      fr: 'le Portugal',
      hu: 'Portugália',
      ca: 'Portugal',
    },
    topLevelDomain: ['.pt'],
    alpha2Code: 'PT',
    alpha3Code: 'PRT',
    callingCodes: ['351'],
    capital: 'Lisbon',
    altSpellings: ['PT', 'Portuguesa', 'Portuguese Republic', 'República Portuguesa'],
    region: 'Europe'
  },
  {
    name: 'Puerto Rico',
    translatedName: {
      en: 'Puerto Rico',
      de: 'Puerto Rico',
      nl: 'Puerto Rico',
      es: 'Puerto Rico',
      fr: 'Porto Rico',
      hu: 'Puerto Rico',
      ca: 'Puerto Rico',
    },
    topLevelDomain: ['.pr'],
    alpha2Code: 'PR',
    alpha3Code: 'PRI',
    callingCodes: ['1'],
    capital: 'San Juan',
    altSpellings: ['PR', 'Commonwealth of Puerto Rico', 'Estado Libre Asociado de Puerto Rico'],
    region: 'Americas'
  },
  {
    name: 'Qatar',
    translatedName: {
      en: 'Qatar',
      de: 'Katar',
      nl: 'Qatar',
      es: 'Qatar',
      fr: 'Qatar',
      hu: 'Katar',
      ca: 'Qatar',
    },
    topLevelDomain: ['.qa'],
    alpha2Code: 'QA',
    alpha3Code: 'QAT',
    callingCodes: ['974'],
    capital: 'Doha',
    altSpellings: ['QA', 'State of Qatar', 'Dawlat Qaṭar'],
    region: 'Asia'
  },
  {
    name: 'Republic of Kosovo',
    translatedName: {
      en: 'Republic of Kosovo',
      de: 'Republik Kosovo',
      nl: 'Republiek Kosovo',
      es: 'República de Kosovo',
      fr: 'République du Kosovo',
      hu: 'Koszovói Köztársaság',
      ca: 'República de Kosovo',
    },
    topLevelDomain: [''],
    alpha2Code: 'XK',
    alpha3Code: 'KOS',
    callingCodes: ['383'],
    capital: 'Pristina',
    altSpellings: ['XK', 'Република Косово'],
    region: 'Europe'
  },
  {
    name: 'Réunion',
    translatedName: {
      en: 'Réunion',
      de: 'Réunion',
      nl: 'Réunion',
      es: 'Réunion',
      fr: 'Réunion',
      hu: 'Réunion',
      ca: 'Réunion',
    },
    topLevelDomain: ['.re'],
    alpha2Code: 'RE',
    alpha3Code: 'REU',
    callingCodes: ['262'],
    capital: 'Saint-Denis',
    altSpellings: ['RE', 'Reunion'],
    region: 'Africa'
  },
  {
    name: 'Romania',
    translatedName: {
      en: 'Romania',
      de: 'Rumänien',
      nl: 'Roemenië',
      es: 'Rumania',
      fr: 'Roumanie',
      hu: 'Románia',
      ca: 'Romania',
    },
    topLevelDomain: ['.ro'],
    alpha2Code: 'RO',
    alpha3Code: 'ROU',
    callingCodes: ['40'],
    capital: 'Bucharest',
    altSpellings: ['RO', 'Rumania', 'Roumania', 'România'],
    region: 'Europe'
  },
  {
    name: 'Russian Federation',
    translatedName: {
      en: 'Russian Federation',
      de: 'Russische Föderation',
      nl: 'Russische Federatie',
      es: 'Federación de Rusa',
      fr: 'Fédération Russe',
      hu: 'Orosz Föderáció',
      ca: 'Federació Russa',
    },
    topLevelDomain: ['.ru'],
    alpha2Code: 'RU',
    alpha3Code: 'RUS',
    callingCodes: ['7'],
    capital: 'Moscow',
    altSpellings: [
      'RU',
      'Rossiya',
      'Russian Federation',
      'Российская Федерация',
      'Rossiyskaya Federatsiya'
    ],
    region: 'Europe'
  },
  {
    name: 'Rwanda',
    translatedName: {
      en: 'Rwanda',
      de: 'Ruanda',
      nl: 'Rwanda',
      es: 'Ruanda',
      fr: 'Rwanda',
      hu: 'Ruanda',
      ca: 'Rwanda',
    },
    topLevelDomain: ['.rw'],
    alpha2Code: 'RW',
    alpha3Code: 'RWA',
    callingCodes: ['250'],
    capital: 'Kigali',
    altSpellings: ['RW', 'Republic of Rwanda', "Repubulika y'u Rwanda", 'République du Rwanda'],
    region: 'Africa'
  },
  {
    name: 'Saint Barthélemy',
    translatedName: {
      en: 'Saint Barthélemy',
      de: 'Saint Barthélemy',
      nl: 'Sint-Bartholomeus',
      es: 'Santa Barthélemy',
      fr: 'Saint Barthélemy',
      hu: 'Szent Barthélemy',
      ca: 'Sant Barthélemy',
    },
    topLevelDomain: ['.bl'],
    alpha2Code: 'BL',
    alpha3Code: 'BLM',
    callingCodes: ['590'],
    capital: 'Gustavia',
    altSpellings: [
      'BL',
      'St. Barthelemy',
      'Collectivity of Saint Barthélemy',
      'Collectivité de Saint-Barthélemy'
    ],
    region: 'Americas'
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    translatedName: {
      en: 'Saint Helena, Ascension and Tristan da Cunha',
      de: 'Saint Helena, Ascension und Tristan da Cunha',
      nl: 'Saint Helena, Ascension en Tristan Da Cunha',
      es: 'Santa Helena, Ascensión y Tristán da Cunha',
      fr: 'Saint Helena, Ascension et Tristan da Cunha',
      hu: 'Szent Helena, Ascension és Tristan Da Cunha',
      ca: 'Santa Helena, Ascension i Tristan da Cunha',
    },
    topLevelDomain: ['.sh'],
    alpha2Code: 'SH',
    alpha3Code: 'SHN',
    callingCodes: ['290'],
    capital: 'Jamestown',
    altSpellings: ['SH'],
    region: 'Africa'
  },
  {
    name: 'Saint Kitts and Nevis',
    translatedName: {
      en: 'Saint Kitts and Nevis',
      de: 'Saint Kitts und Nevis',
      nl: 'Saint Kitts en Nevis',
      es: 'Saint Cristóbal y Nieves',
      fr: 'Saint-Christophe-et-Niévès',
      hu: 'Saint Kitts és Nevis',
      ca: 'Sant Cristòfol i Nevis',
    },
    topLevelDomain: ['.kn'],
    alpha2Code: 'KN',
    alpha3Code: 'KNA',
    callingCodes: ['1'],
    capital: 'Basseterre',
    altSpellings: ['KN', 'Federation of Saint Christopher and Nevis'],
    region: 'Americas'
  },
  {
    name: 'Saint Lucia',
    translatedName: {
      en: 'Saint Lucia',
      de: 'Saint Lucia',
      nl: 'Saint Lucia',
      es: 'Santa Lucía',
      fr: 'Sainte-Lucie',
      hu: 'Saint Lucia',
      ca: 'Saint Lucia',
    },
    topLevelDomain: ['.lc'],
    alpha2Code: 'LC',
    alpha3Code: 'LCA',
    callingCodes: ['1'],
    capital: 'Castries',
    altSpellings: ['LC'],
    region: 'Americas'
  },
  {
    name: 'Saint Martin (French part)',
    translatedName: {
      en: 'Saint Martin (French part)',
      de: 'Saint Martin (Französisch -Teil)',
      nl: 'Saint Martin (Frans deel)',
      es: 'San Martín (parte francesa)',
      fr: 'Saint Martin (partie française)',
      hu: 'Saint Martin (francia rész)',
      ca: 'Saint Martin (part francesa)',
    },
    topLevelDomain: ['.mf', '.fr', '.gp'],
    alpha2Code: 'MF',
    alpha3Code: 'MAF',
    callingCodes: ['590'],
    capital: 'Marigot',
    altSpellings: ['MF', 'Collectivity of Saint Martin', 'Collectivité de Saint-Martin'],
    region: 'Americas'
  },
  {
    name: 'Saint Pierre and Miquelon',
    translatedName: {
      en: 'Saint Pierre and Miquelon',
      de: 'Saint Pierre und Miquelon',
      nl: 'Saint Pierre en Miquelon',
      es: 'Saint Pierre y Miquelon',
      fr: 'Saint-Pierre-et-Miquelon',
      hu: 'Saint Pierre és Miquelon',
      ca: 'Saint Pierre i Miquelon',
    },
    topLevelDomain: ['.pm'],
    alpha2Code: 'PM',
    alpha3Code: 'SPM',
    callingCodes: ['508'],
    capital: 'Saint-Pierre',
    altSpellings: ['PM', 'Collectivité territoriale de Saint-Pierre-et-Miquelon'],
    region: 'Americas'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    translatedName: {
      en: 'Saint Vincent and the Grenadines',
      de: 'Saint Vincent und die Grenadinen',
      nl: 'Saint Vincent en de Grenadines',
      es: 'San Vicente y las Granadinas',
      fr: 'Saint-Vincent-et-les-Grenadines',
      hu: 'Saint Vincent és a Grenadine-szigetek',
      ca: 'Sant Vicenç i les Granadines',
    },
    topLevelDomain: ['.vc'],
    alpha2Code: 'VC',
    alpha3Code: 'VCT',
    callingCodes: ['1'],
    capital: 'Kingstown',
    altSpellings: ['VC'],
    region: 'Americas'
  },
  {
    name: 'Samoa',
    translatedName: {
      en: 'Samoa',
      de: 'Samoa',
      nl: 'Samoa',
      es: 'Samoa',
      fr: 'Samoa',
      hu: 'Szamoa',
      ca: 'Samoa',
    },
    topLevelDomain: ['.ws'],
    alpha2Code: 'WS',
    alpha3Code: 'WSM',
    callingCodes: ['685'],
    capital: 'Apia',
    altSpellings: ['WS', 'Independent State of Samoa', 'Malo Saʻoloto Tutoʻatasi o Sāmoa'],
    region: 'Oceania'
  },
  {
    name: 'San Marino',
    translatedName: {
      en: 'San Marino',
      de: 'San Marino',
      nl: 'San Marino',
      es: 'San Marino',
      fr: 'Saint Marin',
      hu: 'San Marino',
      ca: 'San Marino',
    },
    topLevelDomain: ['.sm'],
    alpha2Code: 'SM',
    alpha3Code: 'SMR',
    callingCodes: ['378'],
    capital: 'City of San Marino',
    altSpellings: ['SM', 'Republic of San Marino', 'Repubblica di San Marino'],
    region: 'Europe'
  },
  {
    name: 'Sao Tome and Principe',
    translatedName: {
      en: 'Sao Tome and Principe',
      de: 'Sao Tome und Principe',
      nl: 'Sao Tome en Principe',
      es: 'Santo Tomé y Príncipe',
      fr: 'Sao tome et principe',
      hu: 'Sao Tome és Principe',
      ca: 'Sao Tomé i Príncep',
    },
    topLevelDomain: ['.st'],
    alpha2Code: 'ST',
    alpha3Code: 'STP',
    callingCodes: ['239'],
    capital: 'São Tomé',
    altSpellings: [
      'ST',
      'Democratic Republic of São Tomé and Príncipe',
      'República Democrática de São Tomé e Príncipe'
    ],
    region: 'Africa'
  },
  {
    name: 'Saudi Arabia',
    translatedName: {
      en: 'Saudi Arabia',
      de: 'Saudi-Arabien',
      nl: 'Saoedi-Arabië',
      es: 'Arabia Saudí',
      fr: 'Arabie Saoudite',
      hu: 'Szaúd-Arábia',
      ca: 'Aràbia Saudita',
    },
    topLevelDomain: ['.sa'],
    alpha2Code: 'SA',
    alpha3Code: 'SAU',
    callingCodes: ['966'],
    capital: 'Riyadh',
    altSpellings: ['SA', 'Kingdom of Saudi Arabia', 'Al-Mamlakah al-‘Arabiyyah as-Su‘ūdiyyah'],
    region: 'Asia'
  },
  {
    name: 'Senegal',
    translatedName: {
      en: 'Senegal',
      de: 'Senegal',
      nl: 'Senegal',
      es: 'Senegal',
      fr: 'Sénégal',
      hu: 'Szenegál',
      ca: 'Senegal',
    },
    topLevelDomain: ['.sn'],
    alpha2Code: 'SN',
    alpha3Code: 'SEN',
    callingCodes: ['221'],
    capital: 'Dakar',
    altSpellings: ['SN', 'Republic of Senegal', 'République du Sénégal'],
    region: 'Africa'
  },
  {
    name: 'Serbia',
    translatedName: {
      en: 'Serbia',
      de: 'Serbien',
      nl: 'Servië',
      es: 'Serbia',
      fr: 'Serbie',
      hu: 'Szerbia',
      ca: 'Sèrbia',
    },
    topLevelDomain: ['.rs'],
    alpha2Code: 'RS',
    alpha3Code: 'SRB',
    callingCodes: ['381'],
    capital: 'Belgrade',
    altSpellings: ['RS', 'Srbija', 'Republic of Serbia', 'Република Србија', 'Republika Srbija'],
    region: 'Europe'
  },
  {
    name: 'Seychelles',
    translatedName: {
      en: 'Seychelles',
      de: 'Seychellen',
      nl: 'Seychellen',
      es: 'Seychelles',
      fr: 'les Seychelles',
      hu: 'Seychelle-szigetek',
      ca: 'Seychelles',
    },
    topLevelDomain: ['.sc'],
    alpha2Code: 'SC',
    alpha3Code: 'SYC',
    callingCodes: ['248'],
    capital: 'Victoria',
    altSpellings: ['SC', 'Republic of Seychelles', 'Repiblik Sesel', 'République des Seychelles'],
    region: 'Africa'
  },
  {
    name: 'Sierra Leone',
    translatedName: {
      en: 'Sierra Leone',
      de: 'Sierra Leone',
      nl: 'Sierra Leone',
      es: 'Sierra Leone',
      fr: 'Sierra Leone',
      hu: 'Sierra Leone',
      ca: 'Sierra Leone',
    },
    topLevelDomain: ['.sl'],
    alpha2Code: 'SL',
    alpha3Code: 'SLE',
    callingCodes: ['232'],
    capital: 'Freetown',
    altSpellings: ['SL', 'Republic of Sierra Leone'],
    region: 'Africa'
  },
  {
    name: 'Singapore',
    translatedName: {
      en: 'Singapore',
      de: 'Singapur',
      nl: 'Singapore',
      es: 'Singapur',
      fr: 'Singapour',
      hu: 'Szingapúr',
      ca: 'Singapur',
    },
    topLevelDomain: ['.sg'],
    alpha2Code: 'SG',
    alpha3Code: 'SGP',
    callingCodes: ['65'],
    capital: 'Singapore',
    altSpellings: ['SG', 'Singapura', 'Republik Singapura', '新加坡共和国'],
    region: 'Asia'
  },
  {
    name: 'Sint Maarten (Dutch part)',
    translatedName: {
      en: 'Sint Maarten (Dutch part)',
      de: 'Sint Maarten (niederländischer Teil)',
      nl: 'Sint Maarten (Nederlands deel)',
      es: 'Sint Maarten (parte holandesa)',
      fr: 'Sint Maarten (partie néerlandaise)',
      hu: 'Sint Maarten (holland rész)',
      ca: 'Sint Maarten (part holandesa)',
    },
    topLevelDomain: ['.sx'],
    alpha2Code: 'SX',
    alpha3Code: 'SXM',
    callingCodes: ['1'],
    capital: 'Philipsburg',
    altSpellings: ['SX'],
    region: 'Americas'
  },
  {
    name: 'Slovakia',
    translatedName: {
      en: 'Slovakia',
      de: 'Slowakei',
      nl: 'Slowakije',
      es: 'Eslovaquia',
      fr: 'Slovaquie',
      hu: 'Szlovákia',
      ca: 'Eslovàquia',
    },
    topLevelDomain: ['.sk'],
    alpha2Code: 'SK',
    alpha3Code: 'SVK',
    callingCodes: ['421'],
    capital: 'Bratislava',
    altSpellings: ['SK', 'Slovak Republic', 'Slovenská republika'],
    region: 'Europe'
  },
  {
    name: 'Slovenia',
    translatedName: {
      en: 'Slovenia',
      de: 'Slowenien',
      nl: 'Slovenië',
      es: 'Eslovenia',
      fr: 'Slovènie',
      hu: 'Szlovénia',
      ca: 'Eslovènia',
    },
    topLevelDomain: ['.si'],
    alpha2Code: 'SI',
    alpha3Code: 'SVN',
    callingCodes: ['386'],
    capital: 'Ljubljana',
    altSpellings: ['SI', 'Republic of Slovenia', 'Republika Slovenija'],
    region: 'Europe'
  },
  {
    name: 'Solomon Islands',
    translatedName: {
      en: 'Solomon Islands',
      de: 'Salomonen',
      nl: 'Solomon eilanden',
      es: 'Islas Salomón',
      fr: 'îles Salomon',
      hu: 'Salamon-szigetek',
      ca: 'Illes Salomó',
    },
    topLevelDomain: ['.sb'],
    alpha2Code: 'SB',
    alpha3Code: 'SLB',
    callingCodes: ['677'],
    capital: 'Honiara',
    altSpellings: ['SB'],
    region: 'Oceania'
  },
  {
    name: 'Somalia',
    translatedName: {
      en: 'Somalia',
      de: 'Somalia',
      nl: 'Somalië',
      es: 'Somalia',
      fr: 'Somalie',
      hu: 'Szomália',
      ca: 'Somàlia',
    },
    topLevelDomain: ['.so'],
    alpha2Code: 'SO',
    alpha3Code: 'SOM',
    callingCodes: ['252'],
    capital: 'Mogadishu',
    altSpellings: [
      'SO',
      'aṣ-Ṣūmāl',
      'Federal Republic of Somalia',
      'Jamhuuriyadda Federaalka Soomaaliya',
      'Jumhūriyyat aṣ-Ṣūmāl al-Fiderāliyya'
    ],
    region: 'Africa'
  },
  {
    name: 'South Africa',
    translatedName: {
      en: 'South Africa',
      de: 'Südafrika',
      nl: 'Zuid-Afrika',
      es: 'Sudáfrica',
      fr: 'Afrique du Sud',
      hu: 'Dél-Afrika',
      ca: 'Sud-Àfrica',
    },
    topLevelDomain: ['.za'],
    alpha2Code: 'ZA',
    alpha3Code: 'ZAF',
    callingCodes: ['27'],
    capital: 'Pretoria',
    altSpellings: ['ZA', 'RSA', 'Suid-Afrika', 'Republic of South Africa'],
    region: 'Africa'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    translatedName: {
      en: 'South Georgia and the South Sandwich Islands',
      de: 'Süd-Georgien und die südlichen Sandwichinseln',
      nl: 'Zuid -Georgia en de South Sandwich Islands',
      es: 'Georgia del sur y las islas Sandwich del sur',
      fr: 'Géorgie du Sud et îles Sandwich du Sud',
      hu: 'Déli-Georgia és a Déli-Sandwich-szigetek',
      ca: 'Geòrgia del Sud i les Illes Sandwich del Sud',
    },
    topLevelDomain: ['.gs'],
    alpha2Code: 'GS',
    alpha3Code: 'SGS',
    callingCodes: ['500'],
    capital: 'King Edward Point',
    altSpellings: ['GS', 'South Georgia and the South Sandwich Islands'],
    region: 'Americas'
  },
  {
    name: 'Korea, Republic',
    translatedName: {
      en: 'Korea, Republic',
      de: 'Korea, Republik',
      nl: 'Korea, Republiek',
      es: 'Corea, República',
      fr: 'Corée, République',
      hu: 'Koreai Köztársaság',
      ca: 'Corea República',
    },
    topLevelDomain: ['.kr'],
    alpha2Code: 'KR',
    alpha3Code: 'KOR',
    callingCodes: ['82'],
    capital: 'Seoul',
    altSpellings: ['KR', 'Republic of Korea'],
    region: 'Asia'
  },
  {
    name: 'South Sudan',
    translatedName: {
      en: 'South Sudan',
      de: 'Südsudan',
      nl: 'Zuid Soedan',
      es: 'Sudán del Sur',
      fr: 'Soudan du sud',
      hu: 'Dél-Szudán',
      ca: 'Sudan del Sud',
    },
    topLevelDomain: ['.ss'],
    alpha2Code: 'SS',
    alpha3Code: 'SSD',
    callingCodes: ['211'],
    capital: 'Juba',
    altSpellings: ['SS'],
    region: 'Africa'
  },
  {
    name: 'Spain',
    translatedName: {
      en: 'Spain',
      de: 'Spanien',
      nl: 'Spanje',
      es: 'España',
      fr: 'Espagne',
      hu: 'Spanyolország',
      ca: 'Espanya',
    },
    topLevelDomain: ['.es'],
    alpha2Code: 'ES',
    alpha3Code: 'ESP',
    callingCodes: ['34'],
    capital: 'Madrid',
    altSpellings: ['ES', 'Kingdom of Spain', 'Reino de España'],
    region: 'Europe'
  },
  {
    name: 'Sri Lanka',
    translatedName: {
      en: 'Sri Lanka',
      de: 'Sri Lanka',
      nl: 'Sri Lanka',
      es: 'Sri Lanka',
      fr: 'Sri Lanka',
      hu: 'Srí Lanka',
      ca: 'Sri Lanka',
    },
    topLevelDomain: ['.lk'],
    alpha2Code: 'LK',
    alpha3Code: 'LKA',
    callingCodes: ['94'],
    capital: 'Colombo',
    altSpellings: ['LK', 'ilaṅkai', 'Democratic Socialist Republic of Sri Lanka'],
    region: 'Asia'
  },
  {
    name: 'Sudan',
    translatedName: {
      en: 'Sudan',
      de: 'Sudan',
      nl: 'Soedan',
      es: 'Sudán',
      fr: 'Soudan',
      hu: 'Szudán',
      ca: 'Sudan',
    },
    topLevelDomain: ['.sd'],
    alpha2Code: 'SD',
    alpha3Code: 'SDN',
    callingCodes: ['249'],
    capital: 'Khartoum',
    altSpellings: ['SD', 'Republic of the Sudan', 'Jumhūrīyat as-Sūdān'],
    region: 'Africa'
  },
  {
    name: 'Suriname',
    translatedName: {
      en: 'Suriname',
      de: 'Surinam',
      nl: 'Surinaam',
      es: 'Suriname',
      fr: 'Suriname',
      hu: 'Suriname',
      ca: 'Surinam',
    },
    topLevelDomain: ['.sr'],
    alpha2Code: 'SR',
    alpha3Code: 'SUR',
    callingCodes: ['597'],
    capital: 'Paramaribo',
    altSpellings: ['SR', 'Sarnam', 'Sranangron', 'Republic of Suriname', 'Republiek Suriname'],
    region: 'Americas'
  },
  {
    name: 'Svalbard and Jan Mayen',
    translatedName: {
      en: 'Svalbard and Jan Mayen',
      de: 'Svalbard und Jan Mayen',
      nl: 'Svalbard en Jan Mayen',
      es: 'Svalbard y Jan Mayen',
      fr: 'Svalbard et Jan Mayen',
      hu: 'Svalbard és Jan Mayen',
      ca: 'Svalbard i Jan Mayen',
    },
    topLevelDomain: ['.sj'],
    alpha2Code: 'SJ',
    alpha3Code: 'SJM',
    callingCodes: ['47'],
    capital: 'Longyearbyen',
    altSpellings: ['SJ', 'Svalbard and Jan Mayen Islands'],
    region: 'Europe'
  },
  {
    name: 'Swaziland',
    translatedName: {
      en: 'Swaziland',
      de: 'Swasiland',
      nl: 'Swaziland',
      es: 'Swaziland',
      fr: 'Swaziland',
      hu: 'Szváziföld',
      ca: 'Swazilàndia',
    },
    topLevelDomain: ['.sz'],
    alpha2Code: 'SZ',
    alpha3Code: 'SWZ',
    callingCodes: ['268'],
    capital: 'Lobamba',
    altSpellings: [
      'SZ',
      'weSwatini',
      'Swatini',
      'Ngwane',
      'Kingdom of Swaziland',
      'Umbuso waseSwatini'
    ],
    region: 'Africa'
  },
  {
    name: 'Sweden',
    translatedName: {
      en: 'Sweden',
      de: 'Schweden',
      nl: 'Zweden',
      es: 'Suecia',
      fr: 'Suède',
      hu: 'Svédország',
      ca: 'Suècia',
    },
    topLevelDomain: ['.se'],
    alpha2Code: 'SE',
    alpha3Code: 'SWE',
    callingCodes: ['46'],
    capital: 'Stockholm',
    altSpellings: ['SE', 'Kingdom of Sweden', 'Konungariket Sverige'],
    region: 'Europe'
  },
  {
    name: 'Switzerland',
    translatedName: {
      en: 'Switzerland',
      de: 'Schweiz',
      nl: 'Zwitserland',
      es: 'Suiza',
      fr: 'Suisse',
      hu: 'Svájc',
      ca: 'Suïssa',
    },
    topLevelDomain: ['.ch'],
    alpha2Code: 'CH',
    alpha3Code: 'CHE',
    callingCodes: ['41'],
    capital: 'Bern',
    altSpellings: ['CH', 'Swiss Confederation', 'Schweiz', 'Suisse', 'Svizzera', 'Svizra'],
    region: 'Europe'
  },
  {
    name: 'Syrian Arab Republic',
    translatedName: {
      en: 'Syrian Arab Republic',
      de: 'Syrische Arabische Republik',
      nl: 'Syrische Arabische Republiek',
      es: 'República Árabe Siria',
      fr: 'République arabe syrienne',
      hu: 'Szíriai Arab Köztársaság',
      ca: 'República Àrab Siriana',
    },
    topLevelDomain: ['.sy'],
    alpha2Code: 'SY',
    alpha3Code: 'SYR',
    callingCodes: ['963'],
    capital: 'Damascus',
    altSpellings: ['SY', 'Syrian Arab Republic', 'Al-Jumhūrīyah Al-ʻArabīyah As-Sūrīyah'],
    region: 'Asia'
  },
  {
    name: 'Taiwan',
    translatedName: {
      en: 'Taiwan',
      de: 'Taiwan',
      nl: 'Taiwan',
      es: 'Taiwán',
      fr: 'Taïwan',
      hu: 'Tajvan',
      ca: 'Taiwan',
    },
    topLevelDomain: ['.tw'],
    alpha2Code: 'TW',
    alpha3Code: 'TWN',
    callingCodes: ['886'],
    capital: 'Taipei',
    altSpellings: ['TW', 'Táiwān', 'Republic of China', '中華民國', 'Zhōnghuá Mínguó'],
    region: 'Asia'
  },
  {
    name: 'Tajikistan',
    translatedName: {
      en: 'Tajikistan',
      de: 'Tadschikistan',
      nl: 'Tadzjikistan',
      es: 'Tayikistán',
      fr: 'Tadjikistan',
      hu: 'Tádzsikisztán',
      ca: 'Tadjikistan',
    },
    topLevelDomain: ['.tj'],
    alpha2Code: 'TJ',
    alpha3Code: 'TJK',
    callingCodes: ['992'],
    capital: 'Dushanbe',
    altSpellings: [
      'TJ',
      'Toçikiston',
      'Republic of Tajikistan',
      'Ҷумҳурии Тоҷикистон',
      'Çumhuriyi Toçikiston'
    ],
    region: 'Asia'
  },
  {
    name: 'Tanzania, United Republik',
    translatedName: {
      en: 'Tanzania, United Republic',
      de: 'Tansania, Vereinigte Republik',
      nl: 'Tanzania, Verenigde Republiek',
      es: 'Tanzania, República Unida',
      fr: 'Tanzanie, République unie',
      hu: 'Tanzániai Egyesült Köztársaság',
      ca: 'Tanzània, República Unida',
    },
    topLevelDomain: ['.tz'],
    alpha2Code: 'TZ',
    alpha3Code: 'TZA',
    callingCodes: ['255'],
    capital: 'Dodoma',
    altSpellings: ['TZ', 'United Republic of Tanzania', 'Jamhuri ya Muungano wa Tanzania'],
    region: 'Africa'
  },
  {
    name: 'Thailand',
    translatedName: {
      en: 'Thailand',
      de: 'Thailand',
      nl: 'Thailand',
      es: 'Tailandia',
      fr: 'Thaïlande',
      hu: 'Thaiföld',
      ca: 'Tailàndia',
    },
    topLevelDomain: ['.th'],
    alpha2Code: 'TH',
    alpha3Code: 'THA',
    callingCodes: ['66'],
    capital: 'Bangkok',
    altSpellings: [
      'TH',
      'Prathet',
      'Thai',
      'Kingdom of Thailand',
      'ราชอาณาจักรไทย',
      'Ratcha Anachak Thai'
    ],
    region: 'Asia'
  },
  {
    name: 'Timor-Leste',
    translatedName: {
      en: 'Timor-Leste',
      de: 'Timor-Leste',
      nl: 'Timor-Leste',
      es: 'Timor-Leste',
      fr: 'Timor-Leste',
      hu: 'Kelet-Timor',
      ca: 'Timor-Leste',
    },
    topLevelDomain: ['.tl'],
    alpha2Code: 'TL',
    alpha3Code: 'TLS',
    callingCodes: ['670'],
    capital: 'Dili',
    altSpellings: [
      'TL',
      'East Timor',
      'Democratic Republic of Timor-Leste',
      'República Democrática de Timor-Leste',
      'Repúblika Demokrátika Timór-Leste'
    ],
    region: 'Asia'
  },
  {
    name: 'Togo',
    translatedName: {
      en: 'Togo',
      de: 'Togo',
      nl: 'Togo',
      es: 'Togo',
      fr: 'Togo',
      hu: 'Togo',
      ca: 'Togo',
    },
    topLevelDomain: ['.tg'],
    alpha2Code: 'TG',
    alpha3Code: 'TGO',
    callingCodes: ['228'],
    capital: 'Lomé',
    altSpellings: ['TG', 'Togolese', 'Togolese Republic', 'République Togolaise'],
    region: 'Africa'
  },
  {
    name: 'Tokelau',
    translatedName: {
      en: 'Tokelau',
      de: 'ToKelau',
      nl: 'Tokelau',
      es: 'Tokelau',
      fr: 'Tokelau',
      hu: 'Tokelau',
      ca: 'Tokelau',
    },
    topLevelDomain: ['.tk'],
    alpha2Code: 'TK',
    alpha3Code: 'TKL',
    callingCodes: ['690'],
    capital: 'Fakaofo',
    altSpellings: ['TK'],
    region: 'Oceania'
  },
  {
    name: 'Tonga',
    translatedName: {
      en: 'Tonga',
      de: 'Tonga',
      nl: 'Tonga',
      es: 'Tonga',
      fr: 'Tonga',
      hu: 'Tonga',
      ca: 'Tonga',
    },
    topLevelDomain: ['.to'],
    alpha2Code: 'TO',
    alpha3Code: 'TON',
    callingCodes: ['676'],
    capital: "Nuku'alofa",
    altSpellings: ['TO'],
    region: 'Oceania'
  },
  {
    name: 'Trinidad and Tobago',
    translatedName: {
      en: 'Trinidad and Tobago',
      de: 'Trinidad und Tobago',
      nl: 'Trinidad en Tobago',
      es: 'Trinidad y Tobago',
      fr: 'Trinité-et-Tobago',
      hu: 'Trinidad és Tobago',
      ca: 'Trinitat i Tobago',
    },
    topLevelDomain: ['.tt'],
    alpha2Code: 'TT',
    alpha3Code: 'TTO',
    callingCodes: ['1'],
    capital: 'Port of Spain',
    altSpellings: ['TT', 'Republic of Trinidad and Tobago'],
    region: 'Americas'
  },
  {
    name: 'Tunisia',
    translatedName: {
      en: 'Tunisia',
      de: 'Tunesien',
      nl: 'Tunesië',
      es: 'Túnez',
      fr: 'Tunisie',
      hu: 'Tunézia',
      ca: 'Tunísia',
    },
    topLevelDomain: ['.tn'],
    alpha2Code: 'TN',
    alpha3Code: 'TUN',
    callingCodes: ['216'],
    capital: 'Tunis',
    altSpellings: ['TN', 'Republic of Tunisia', 'al-Jumhūriyyah at-Tūnisiyyah'],
    region: 'Africa'
  },
  {
    name: 'Turkey',
    translatedName: {
      en: 'Turkey',
      de: 'Türkei',
      nl: 'Turkije',
      es: 'Turquía',
      fr: 'Turquie',
      hu: 'Törökország',
      ca: 'Turquia',
    },
    topLevelDomain: ['.tr'],
    alpha2Code: 'TR',
    alpha3Code: 'TUR',
    callingCodes: ['90'],
    capital: 'Ankara',
    altSpellings: ['TR', 'Turkiye', 'Republic of Turkey', 'Türkiye Cumhuriyeti'],
    region: 'Asia'
  },
  {
    name: 'Turkmenistan',
    translatedName: {
      en: 'Turkmenistan',
      de: 'Turkmenistan',
      nl: 'Turkmenistan',
      es: 'Turkmenistán',
      fr: 'Turkménistan',
      hu: 'Türkmenisztán',
      ca: 'Turkmenistan',
    },
    topLevelDomain: ['.tm'],
    alpha2Code: 'TM',
    alpha3Code: 'TKM',
    callingCodes: ['993'],
    capital: 'Ashgabat',
    altSpellings: ['TM'],
    region: 'Asia'
  },
  {
    name: 'Turks and Caicos Islands',
    translatedName: {
      en: 'Turks and Caicos Islands',
      de: 'Türken und Caicos-Inseln',
      nl: 'Turken en Caicos-eilanden',
      es: 'Islas Turcas y Caicos',
      fr: 'îles Turques-et-Caïques',
      hu: 'Turks- és Caicos-szigetek',
      ca: 'Illes Turks i Caicos',
    },
    topLevelDomain: ['.tc'],
    alpha2Code: 'TC',
    alpha3Code: 'TCA',
    callingCodes: ['1'],
    capital: 'Cockburn Town',
    altSpellings: ['TC'],
    region: 'Americas'
  },
  {
    name: 'Tuvalu',
    translatedName: {
      en: 'Tuvalu',
      de: 'Tuvalu',
      nl: 'Tuvalu',
      es: 'Tuvalu',
      fr: 'Tuvalu',
      hu: 'Tuvalu',
      ca: 'Tuvalu',
    },
    topLevelDomain: ['.tv'],
    alpha2Code: 'TV',
    alpha3Code: 'TUV',
    callingCodes: ['688'],
    capital: 'Funafuti',
    altSpellings: ['TV'],
    region: 'Oceania'
  },
  {
    name: 'Uganda',
    translatedName: {
      en: 'Uganda',
      de: 'Uganda',
      nl: 'Oeganda',
      es: 'Uganda',
      fr: 'Ouganda',
      hu: 'Uganda',
      ca: 'Uganda',
    },
    topLevelDomain: ['.ug'],
    alpha2Code: 'UG',
    alpha3Code: 'UGA',
    callingCodes: ['256'],
    capital: 'Kampala',
    altSpellings: ['UG', 'Republic of Uganda', 'Jamhuri ya Uganda'],
    region: 'Africa'
  },
  {
    name: 'Ukraine',
    translatedName: {
      en: 'Ukraine',
      de: 'Ukraine',
      nl: 'Oekraïne',
      es: 'Ucrania',
      fr: 'Ukraine',
      hu: 'Ukrajna',
      ca: 'Ucraïna',
    },
    topLevelDomain: ['.ua'],
    alpha2Code: 'UA',
    alpha3Code: 'UKR',
    callingCodes: ['380'],
    capital: 'Kiev',
    altSpellings: ['UA', 'Ukrayina'],
    region: 'Europe'
  },
  {
    name: 'United Arab Emirates',
    translatedName: {
      en: 'United Arab Emirates',
      de: 'Vereinigte Arabische Emirate',
      nl: 'Verenigde Arabische Emiraten',
      es: 'Emiratos Árabes Unidos',
      fr: 'Emirats Arabes Unis',
      hu: 'Egyesült Arab Emírségek',
      ca: 'Unió dels Emirats Àrabs',
    },
    topLevelDomain: ['.ae'],
    alpha2Code: 'AE',
    alpha3Code: 'ARE',
    callingCodes: ['971'],
    capital: 'Abu Dhabi',
    altSpellings: ['AE', 'UAE'],
    region: 'Asia'
  },
  {
    name: 'United Kingdom',
    translatedName: {
      en: 'United Kingdom',
      de: 'Vereinigtes Königreich',
      nl: 'Verenigd Koninkrijk',
      es: 'Reino Unido',
      fr: 'Royaume-Uni',
      hu: 'Egyesült Királyság',
      ca: 'Regne Unit',
    },
    topLevelDomain: ['.uk'],
    alpha2Code: 'GB',
    alpha3Code: 'GBR',
    callingCodes: ['44'],
    capital: 'London',
    altSpellings: ['GB', 'UK', 'Great Britain'],
    region: 'Europe'
  },
  {
    name: 'United States of America',
    translatedName: {
      en: 'United States of America',
      de: 'Vereinigte Staaten von Amerika',
      nl: 'Verenigde Staten van Amerika',
      es: 'Estados Unidos de America',
      fr: "les États-Unis d'Amérique",
      hu: 'Amerikai Egyesült Államok',
      ca: 'Estats Units d\'Amèrica',
    },
    topLevelDomain: ['.us'],
    alpha2Code: 'US',
    alpha3Code: 'USA',
    callingCodes: ['1'],
    capital: 'Washington, D.C.',
    altSpellings: ['US', 'USA', 'United States of America'],
    region: 'Americas'
  },
  {
    name: 'Uruguay',
    translatedName: {
      en: 'Uruguay',
      de: 'Uruguay',
      nl: 'Uruguay',
      es: 'Uruguay',
      fr: 'Uruguay',
      hu: 'Uruguay',
      ca: 'Uruguai',
    },
    topLevelDomain: ['.uy'],
    alpha2Code: 'UY',
    alpha3Code: 'URY',
    callingCodes: ['598'],
    capital: 'Montevideo',
    altSpellings: ['UY', 'Oriental Republic of Uruguay', 'República Oriental del Uruguay'],
    region: 'Americas'
  },
  {
    name: 'Uzbekistan',
    translatedName: {
      en: 'Uzbekistan',
      de: 'Usbekistan',
      nl: 'Oezbekistan',
      es: 'Uzbekistán',
      fr: 'Ouzbékistan',
      hu: 'Üzbegisztán',
      ca: 'Uzbekistan',
    },
    topLevelDomain: ['.uz'],
    alpha2Code: 'UZ',
    alpha3Code: 'UZB',
    callingCodes: ['998'],
    capital: 'Tashkent',
    altSpellings: [
      'UZ',
      'Republic of Uzbekistan',
      'O‘zbekiston Respublikasi',
      'Ўзбекистон Республикаси'
    ],
    region: 'Asia'
  },
  {
    name: 'Vanuatu',
    translatedName: {
      en: 'Vanuatu',
      de: 'Vanuatu',
      nl: 'Vanuatu',
      es: 'Vanuatu',
      fr: 'Vanuatu',
      hu: 'Vanuatu',
      ca: 'Vanuatu',
    },
    topLevelDomain: ['.vu'],
    alpha2Code: 'VU',
    alpha3Code: 'VUT',
    callingCodes: ['678'],
    capital: 'Port Vila',
    altSpellings: ['VU', 'Republic of Vanuatu', 'Ripablik blong Vanuatu', 'République de Vanuatu'],
    region: 'Oceania'
  },
  {
    name: 'Venezuela, Bolivarian Republic',
    translatedName: {
      en: 'Venezuela, Bolivarian Republic',
      de: 'Venezuela, Bolivarische Republik',
      nl: 'Venezuela, Bolivarian Republiek',
      es: 'Venezuela, República Bolivariana',
      fr: 'Venezuela, République bolivarienne',
      hu: 'Venezuela, Bolivári Köztársaság',
      ca: 'Veneçuela, República Bolivariana',
    },
    topLevelDomain: ['.ve'],
    alpha2Code: 'VE',
    alpha3Code: 'VEN',
    callingCodes: ['58'],
    capital: 'Caracas',
    altSpellings: ['VE', 'Bolivarian Republic of Venezuela', 'República Bolivariana de Venezuela'],
    region: 'Americas'
  },
  {
    name: 'Viet Nam',
    translatedName: {
      en: 'Viet Nam',
      de: 'Vietnam',
      nl: 'Vietnam',
      es: 'Vietnam',
      fr: 'Viet Nam',
      hu: 'Vietnam',
      ca: 'Vietnam',
    },
    topLevelDomain: ['.vn'],
    alpha2Code: 'VN',
    alpha3Code: 'VNM',
    callingCodes: ['84'],
    capital: 'Hanoi',
    altSpellings: ['VN', 'Socialist Republic of Vietnam', 'Cộng hòa Xã hội chủ nghĩa Việt Nam'],
    region: 'Asia'
  },
  {
    name: 'Wallis and Futuna',
    translatedName: {
      en: 'Wallis and Futuna',
      de: 'Wallis und Futuna',
      nl: 'Wallis en Futuna',
      es: 'Wallis y Futuna',
      fr: 'Wallis et Futuna',
      hu: 'Wallis és Futuna',
      ca: 'Wallis i Futuna',
    },
    topLevelDomain: ['.wf'],
    alpha2Code: 'WF',
    alpha3Code: 'WLF',
    callingCodes: ['681'],
    capital: 'Mata-Utu',
    altSpellings: [
      'WF',
      'Territory of the Wallis and Futuna Islands',
      'Territoire des îles Wallis et Futuna'
    ],
    region: 'Oceania'
  },
  {
    name: 'Western Sahara',
    translatedName: {
      en: 'Western Sahara',
      de: 'Westsahara',
      nl: 'Westelijke Sahara',
      es: 'Sáhara Occidental',
      fr: 'Sahara Occidental',
      hu: 'Nyugat-Szahara',
      ca: 'Sàhara Occidental',
    },
    topLevelDomain: ['.eh'],
    alpha2Code: 'EH',
    alpha3Code: 'ESH',
    callingCodes: ['212'],
    capital: 'El Aaiún',
    altSpellings: ['EH', 'Taneẓroft Tutrimt'],
    region: 'Africa'
  },
  {
    name: 'Yemen',
    translatedName: {
      en: 'Yemen',
      de: 'Jemen',
      nl: 'Jemen',
      es: 'Yemen',
      fr: 'Yémen',
      hu: 'Jemen',
      ca: 'Iemen',
    },
    topLevelDomain: ['.ye'],
    alpha2Code: 'YE',
    alpha3Code: 'YEM',
    callingCodes: ['967'],
    capital: "Sana'a",
    altSpellings: ['YE', 'Yemeni Republic', 'al-Jumhūriyyah al-Yamaniyyah'],
    region: 'Asia'
  },
  {
    name: 'Zambia',
    translatedName: {
      en: 'Zambia',
      de: 'Sambia',
      nl: 'Zambia',
      es: 'Zambia',
      fr: 'Zambie',
      hu: 'Zambia',
      ca: 'Zàmbia',
    },
    topLevelDomain: ['.zm'],
    alpha2Code: 'ZM',
    alpha3Code: 'ZMB',
    callingCodes: ['260'],
    capital: 'Lusaka',
    altSpellings: ['ZM', 'Republic of Zambia'],
    region: 'Africa'
  },
  {
    name: 'Zimbabwe',
    translatedName: {
      en: 'Zimbabwe',
      de: 'Simbabwe',
      nl: 'Zimbabwe',
      es: 'Zimbabue',
      fr: 'Zimbabwe',
      hu: 'Zimbabwe',
      ca: 'Zimbabwe',
    },
    topLevelDomain: ['.zw'],
    alpha2Code: 'ZW',
    alpha3Code: 'ZWE',
    callingCodes: ['263'],
    capital: 'Harare',
    altSpellings: ['ZW', 'Republic of Zimbabwe'],
    region: 'Africa'
  }
];
