import { LANGUAGES } from './constants';

export const LANGUAGES_DATA = [
  {
    id: 1,
    name: 'English',
    translatedName: {
      en: 'English',
      de: 'Englisch',
      nl: 'Engels',
      es: 'Inglés',
      fr: 'Anglais',
      hu: 'Angol',
      ca: 'Anglès',
    },
    alpha2Code: LANGUAGES.EN,
  },
  {
    id: 2,
    name: 'German',
    translatedName: {
      en: 'German',
      de: 'Deutsch',
      nl: 'Duits',
      es: 'Alemán',
      fr: 'Allemand',
      hu: 'Német',
      ca: 'Alemany',
    },
    alpha2Code: LANGUAGES.DE,
  },
  {
    id: 3,
    name: 'Dutch',
    translatedName: {
      en: 'Dutch',
      de: 'Niederländisch',
      nl: 'Nederlands',
      es: 'Holandés',
      fr: 'Néerlandais',
      hu: 'Holland',
      ca: 'Holandès',
    },
    alpha2Code: LANGUAGES.NL,
  },
  {
    id: 4,
    name: 'Spanish',
    translatedName: {
      en: 'Spanish',
      de: 'Spanisch',
      nl: 'Spaans',
      es: 'Español',
      fr: 'Espagnol',
      hu: 'Spanyol',
      ca: 'Espanyol',
    },
    alpha2Code: LANGUAGES.ES,
  },
  /* {
    id: 5,
    name: 'French',
    translatedName: {
      en: 'French',
      de: 'Französisch',
      nl: 'Frans',
      es: 'Francés',
      fr: 'Français',
      hu: 'Francia',
      ca: 'Francès',
    },
    alpha2Code: LANGUAGES.FR,
  }, */
  {
    id: 6,
    name: 'Hungarian',
    translatedName: {
      en: 'Hungarian',
      de: 'Ungarisch',
      nl: 'Hongaars',
      es: 'Húngaro',
      fr: 'Hongrois',
      hu: 'Magyar',
      ca: 'Hongarès',
    },
    alpha2Code: LANGUAGES.HU,
  },
  {
    id: 7,
    name: 'Catalan',
    translatedName: {
      en: 'Catalan',
      de: 'Katalanisch',
      nl: 'Catalaans',
      es: 'Catalán',
      fr: 'Catalan',
      hu: 'Katalán',
      ca: 'Calalana',
    },
    alpha2Code: LANGUAGES.CA,
  },
];
