import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import QuestionAnswerBlock from '../../../common/QuestionAnswerBlock';

import './index.scss';

const FaqPage = () => {
  const intl = useIntl();

  const qaList = useMemo(() => {
    if (intl.messages.user) {
      const { questions, answers } = intl.messages.user.faqPage || {};

      return Object.keys(questions).map((key) => ({
        question: questions[key],
        answer: answers[key],
      }));
    }
    return [];
  }, [intl]);

  if (!intl) {
    return null;
  }

  return (
    <div className="faq-page">
      <div className="faq-page__container">
        <h1 className="faq-page__title">{intl.messages.user?.faqPage.title}</h1>
        <div className="faq-page__content">
          {qaList.map((item) => (
            <QuestionAnswerBlock key={item.id} qaItem={item} />
          ))}
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default FaqPage;
