import React, { useMemo, useState } from 'react';
import * as classnames from 'classnames';
import { useIntl } from 'react-intl';

import './index.scss';

const hyperlinkRegexp = /(http[\w:/.-]*)/g;

const AnswerSections = ({ content }) => {
  const sections = useMemo(() => {
    return content.split(hyperlinkRegexp);
  }, [content]);

  if (!sections) {
    return null;
  }

  return (
    <>
      {sections.map((section, idx) => {
        if (!section.length) {
          return null;
        }

        if (section.includes('http')) {
          return (
            <a href={section} target="_blank" rel="noopener noreferrer">
              <span>{section}</span>
            </a>
          );
        } else {
          return <span>{section}</span>;
        }
      })}
    </>
  );
};

const QuestionAnswerBlock = ({ qaItem }) => {
  const { question, answer } = qaItem;
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  if (!qaItem || !qaItem.question || !qaItem.answer) {
    return null;
  }

  return (
    <div className="question-answer-block">
      <div className="question-answer-block__header">
        <h3 className="question-answer-block__title">{`Q: ${JSON.stringify(question)}`}</h3>
        <div
          className={classnames('question-answer-block__toggle', isOpen ? 'open' : '')}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>
            {isOpen ? intl.messages.user?.faqPage.hide : intl.messages.user?.faqPage.show}
          </span>
        </div>
      </div>
      <div className={classnames('question-answer-block__panel', isOpen ? 'open' : '')}>
        <div className="question-answer-block__panel-content">
          <p>
            <span>{'A: '}</span>
            <AnswerSections content={answer} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuestionAnswerBlock;
