export const REDUCER_TYPES = {
  SET_STORAGE: 'setStorage',
  SET_ERROR_MODAL: 'setErrorModal',
  SET_IMAGE_MODAL: 'setImageModal',
  SET_MODAL_DATA: 'setModalData',
  SET_PROMPT_MODAL_DATA: 'setPromptModalData',
  SET_SNACK_BAR_DATA: 'setSnackBarData',
  SET_ASSESSMENT_LANGUAGE: 'setAssessmentLanguage',
  SET_IS_LOADING: 'setIsLoading',
  SET_TOKEN: 'setToken',
  SET_SELECTED_LANGUAGE: 'setSelectedLanguage',
  SET_SELECTED_PARTICIPANT_LANGUAGE: 'setSelectedParticipantLanguage',
  SET_HELPERS: 'setHelpers',
  SET_USER: 'setUser',
  SET_CDK_MODULE: 'setCdkModule',
  SET_CDK_LIST_SEARCH_PARAMS: 'setCdkListSearchParams',
  SET_ASSESSMENT_TYPE_QUESTIONS_DATA: 'setAssessmentTypeQuestionsData',
  SET_SELECTED_ASSESSMENT_TYPE: 'setSelectedAssessmentType',
  NULL_ERROR_MODAL: 'nullErrorModal',
  NULL_IMAGE_MODAL: 'nullImageModal',
  NULL_MODAL_DATA: 'nullModalData',
  NULL_PROMPT_MODAL_DATA: 'nullPromptModalData',
  NULL_SNACK_BAR_DATA: 'nullSnackBarData',
  NULL_TOKEN: 'nullToken',
  NULL_USER: 'nullUser',
};
