// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/icons/star-grey.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../assets/icons/star-yellow-light.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../../assets/icons/star-yellow.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".star-input {\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n  .star-input:hover {\n    cursor: pointer; }\n  .star-input.-is-disabled {\n    pointer-events: none; }\n  .star-input__star {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), none;\n    background-repeat: no-repeat;\n    background-size: contain;\n    width: 33px;\n    height: 32px; }\n    .star-input__star.-highlighted {\n      background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "), none;\n      background-repeat: no-repeat;\n      background-size: contain; }\n    .star-input__star.-active {\n      background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + "), none;\n      background-repeat: no-repeat;\n      background-size: contain; }\n", "",{"version":3,"sources":["webpack://./src/components/common/ui/StarInput/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA;EAHrB;IAMI,eAAe,EAAA;EANnB;IAUI,oBAAoB,EAAA;EAGtB;IACE,+DAA0D;IAC1D,4BAA4B;IAC5B,wBAAwB;IACxB,WAAW;IACX,YAAY,EAAA;IALb;MAQG,+DAAkE;MAClE,4BAA4B;MAC5B,wBAAwB,EAAA;IAV3B;MAcG,+DAA4D;MAC5D,4BAA4B;MAC5B,wBAAwB,EAAA","sourcesContent":[".star-input {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  &.-is-disabled {\n    pointer-events: none;\n  }\n\n  &__star {\n    background-image: url('/assets/icons/star-grey.svg'), none;\n    background-repeat: no-repeat;\n    background-size: contain;\n    width: 33px;\n    height: 32px;\n\n    &.-highlighted {\n      background-image: url('/assets/icons/star-yellow-light.svg'), none;\n      background-repeat: no-repeat;\n      background-size: contain;\n    }\n\n    &.-active {\n      background-image: url('/assets/icons/star-yellow.svg'), none;\n      background-repeat: no-repeat;\n      background-size: contain;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
