import React from 'react';
import { useParams } from 'react-router';
import { getCdkAreas } from '../../api/services/Cdk';
import { useLoadingLayer } from './useLoadingLayer';
import { useShowErrorModal } from './useShowErrorModal';

const DEFAULT_AREA_LIST = ['Ideas and Opportunities ', 'Resources', 'Into Action'];

export const useCdkAreas = () => {
  const [areas, setAreas] = React.useState([]);
  const [isInitialized, setIsInitialized] = React.useState(false);

  const { cdkRepositoryType } = useParams();
  const loadingLayer = useLoadingLayer();
  const showErrorModal = useShowErrorModal();

  const getAreas = React.useCallback(async () => {
    try {
      loadingLayer.show();
      const response = await getCdkAreas(cdkRepositoryType);
      const { data, status } = response;

      if (status >= 200 && status < 300 && data) {
        const areaList = data.data;

        if (areaList.length > 0) {
          const areaListUsed = [...DEFAULT_AREA_LIST];

          for (let area of areaList) {
            if (!areaListUsed.includes(area)) {
              areaListUsed.push(area);
            }
          }
          setAreas(areaListUsed);
        }
      }
    } catch (err) {
      showErrorModal(err);
    } finally {
      setIsInitialized(true);
      loadingLayer.hide();
    }
  }, [cdkRepositoryType, loadingLayer, showErrorModal]);

  React.useEffect(() => {
    getAreas();
  }, [getAreas]);

  return React.useMemo(() => ({ areas, isInitialized }), [areas, isInitialized]);
};
